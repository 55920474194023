import { CameraIcon } from "@heroicons/react/20/solid";
import { LinkButtonPrimary } from "components/Button";
import { useLoader } from "components/PageLoading";
import { graphql } from "generated";
import { constructLoginRedirectPath } from "lib/provideUrql";
import useDocumentTitle from "lib/useDocumentTitle";
import { useEffect } from "react";
import { Navigate, useLocation, useSearchParams } from "react-router-dom";
import { useMutation } from "urql";

const SubmitAttendanceJwtDocument = graphql(`
    mutation SubmitAttendanceJwt($jwtCode: String!) {
        submitAttendanceJwt(jwtCode: $jwtCode) {
            __typename
            ... on SelfServiceAttendanceResultSuccess {
                timestamp
                type
                subject {
                    __typename
                    id
                    name
                    ... on ProjectSession {
                        project {
                            id
                            name
                        }
                    }
                }
            }
            ... on SelfServiceAttendanceResultFail {
                reason
            }
        }
    }
`)

export default function AttendancePage() {
    useDocumentTitle("Attendance | AIAA@UCF Members")
    const loader = useLoader()
    const { pathname, search } = useLocation()

    const [searchParams] = useSearchParams()
    const code = searchParams.get("c")

    const [result, submitAttendanceJwt] = useMutation(SubmitAttendanceJwtDocument)

    useEffect(() => {
        if ((code?.length ?? 0) > 0) {
            loader.start()
            submitAttendanceJwt({ jwtCode: code! }).finally(loader.stop)
        }
    }, [code, loader, submitAttendanceJwt])

    if (result.error?.graphQLErrors?.some(e => e.extensions.code === "UNAUTHENTICATED")) {
        return <Navigate to={constructLoginRedirectPath(pathname + search)} />
    }

    if ((code?.length ?? 0) === 0) {
        return (
            <div className="flex flex-col justify-center items-center w-full mt-6">
                <h2 className="m-6 w-auto text-2xl text-center">
                    Scan an attendance QR code to be marked present
                </h2>
                <LinkButtonPrimary to="/attendance/scan"><CameraIcon className="h-5 w-5 inline" /> Scan</LinkButtonPrimary>
            </div>
        )
    }
    if (result.data?.submitAttendanceJwt?.__typename === "SelfServiceAttendanceResultFail") {
        return (
            <div className="flex flex-col justify-center items-center w-full mt-6">
                <h2 className="mt-6 w-auto text-2xl text-red-600">
                    ERROR
                </h2>
                <h2 className="m-6 w-auto text-2xl text-center">
                    {result.data.submitAttendanceJwt.reason}
                </h2>
                <LinkButtonPrimary to="/attendance/scan"><CameraIcon className="h-5 w-5 inline" /> Scan Again</LinkButtonPrimary>
            </div>
        )
    }

    if (result.data?.submitAttendanceJwt?.__typename === "SelfServiceAttendanceResultSuccess") {
        return (
            <div className="flex flex-col justify-center items-center w-full mt-6">
                <h2 className="mt-6 w-auto text-2xl text-green-500">
                    SUCCESS
                </h2>
                <h2 className="m-6 w-auto text-2xl text-center">
                    You have been marked present for&nbsp;
                    {result.data.submitAttendanceJwt.subject?.__typename === "SemesterGeneralBodyMeetings" && "today's GBM!"}
                    {result.data.submitAttendanceJwt.subject?.__typename === "ProjectSession" && result.data.submitAttendanceJwt.subject.project?.name}
                    {result.data.submitAttendanceJwt.subject?.__typename === "OneOffEvent" && "today's event!"}
                </h2>
            </div>
        )
    }

    if (result.fetching) {
        return (
            <div className="flex flex-col justify-center items-center w-full mt-6">
                <h2 className="mt-6 w-auto text-2xl">
                    Loading...
                </h2>
            </div>
        )
    }

    return (
        <div className="flex flex-col justify-center items-center w-full mt-6">
            <h2 className="mt-6 w-auto text-2xl text-red-600">
                ERROR
            </h2>
            <h2 className="m-6 w-auto text-2xl text-center">
                An unknown error occurred
            </h2>
            <LinkButtonPrimary to="/attendance/scan"><CameraIcon className="h-5 w-5 inline" /> Scan Again</LinkButtonPrimary>
        </div>
    )

}