import { FragmentType, getFragmentData, graphql } from "generated";
import { Link, NavLink } from "react-router-dom";

export const AccountNavigationSidebar_UserFragment = graphql(`
    fragment AccountNavigationSidebar_UserFragment on User {
        id
        hasDemographics
    }
`)

type Props = {
    user: FragmentType<typeof AccountNavigationSidebar_UserFragment>
}

export default function AccountNavigationSidebar(props: Props) {
    const user = getFragmentData(AccountNavigationSidebar_UserFragment, props.user)

    const classes = "flex items-center py-2 px-4 border border-b-0 border-gray-400 first:rounded-t last:rounded-b last:border-b hover:bg-gray-200 [&.active]:bg-aiaalightblue [&.active]:text-white"

    return (
        <div className="flex flex-col items-stretch md:mx-4">
            <NavLink className={classes} to="/account/profile">Profile Settings</NavLink>
            <NavLink className={classes} to="/account/emails">Emails</NavLink>
            <NavLink className={classes} to="/account/membership">Membership</NavLink>
            <NavLink className={classes} to="/account/demographics">
                Demographics
                {user.hasDemographics === false && (
                    <span className="ml-auto relative flex h-4 w-4">
                        <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-amber-400 opacity-75"></span>
                        <span className="relative inline-flex rounded-full h-4 w-4 bg-amber-500"></span>
                    </span>
                )}
            </NavLink>
            <Link className={classes} to="/myqr">MyQR</Link>
        </div>
    )
}