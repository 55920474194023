import { useState } from "react"
import { ButtonPrimary } from "./Button"
import { useMutation } from "urql";
import { useLoader } from "./PageLoading";
import { FragmentType, getFragmentData, graphql } from "generated";

export const EditAccountProfile_UserFragment = graphql(`
    fragment EditAccountProfile_UserFragment on User {
        id
        givenName
        fullName
        profilePicture
    }
`)

export const EditAccountProfile_SetMyGivenName = graphql(`
    mutation EditAccountProfile_SetMyGivenName($newGivenName: String!) {
        setMyGivenName(newGivenName: $newGivenName) {
            ...EditAccountProfile_UserFragment
        }
    }
`)

type Props = {
    user: FragmentType<typeof EditAccountProfile_UserFragment>
    onSave(changed: boolean): void
    onError(err: string): void
}

export default function EditAccountProfile(props: Props) {
    const loader = useLoader()
    const [, setMyGivenName] = useMutation(EditAccountProfile_SetMyGivenName)
    const user = getFragmentData(EditAccountProfile_UserFragment, props.user)

    const [givenName, setGivenName] = useState(user.givenName)

    const save = async () => {
        if (givenName === user.givenName) {
            return props.onSave(false)
        }
        if (givenName.length === 0) {
            return props.onError("You must enter a given name")
        }
        try {
            loader.start()
            const r = await setMyGivenName({
                newGivenName: givenName
            })
            if ((r.error?.graphQLErrors.length ?? 0) > 0) {
                return props.onError(r.error!.graphQLErrors[0].message)
            }
            props.onSave(true)
        } finally {
            loader.stop()
        }
    }

    return (
        <div className="w-full max-w-xs">
            <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2">
                    Full Name
                </label>
                <input
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline hover:cursor-not-allowed bg-gray-300"
                    type="text"
                    value={user.fullName}
                    readOnly
                    disabled
                />
            </div>
            <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2">
                    Given Name
                </label>
                <input
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    type="text"
                    value={givenName}
                    onChange={(e) => setGivenName(e.target.value)}
                />
            </div>
            <div className="mb-4">
                <ButtonPrimary onClick={save}>
                    Save
                </ButtonPrimary>
            </div>
        </div>
    )
}