import { FragmentType, getFragmentData, graphql } from "generated"
import { ButtonPrimary } from "./Button"
import { useLoader } from "./PageLoading";
import { CheckIcon, XMarkIcon } from "@heroicons/react/24/solid";
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import { useState } from "react";
import { useMutation } from "urql";
import SimpleModal from "./SimpleModal";

export const EditAccountEmailPreferences_UserFragment = graphql(`
    fragment EditAccountEmailPreferences_UserFragment on User {
        id
        preferences {
            clubEmailConsent
            businessEmailConsent
        }
    }
`)

export const EditAccountEmailPreferences_SetMyClubEmailConsent = graphql(`
    mutation EditAccountEmailPreferences_SetMyClubEmailConsent($consent: Boolean!) {
        setMyClubEmailConsent(consent: $consent) {
            ...EditAccountEmailPreferences_UserFragment
        }
    }
`)

export const EditAccountEmailPreferences_SetMyBusinessEmailConsent = graphql(`
    mutation EditAccountEmailPreferences_SetMyBusinessEmailConsent($consent: Boolean!) {
        setMyBusinessEmailConsent(consent: $consent) {
            ...EditAccountEmailPreferences_UserFragment
        }
    }
`)


type Props = {
    user: FragmentType<typeof EditAccountEmailPreferences_UserFragment>
    startBlank?: boolean
    onSave(changed: boolean): void
    onError(err: string): void
}

export default function EditAccountEmailPreferences(props: Props) {
    const loader = useLoader()
    const user = getFragmentData(EditAccountEmailPreferences_UserFragment, props.user)
    const [clubEmailConsent, setClubEmailConsent] = useState<boolean | null>(props.startBlank ? null : user.preferences.clubEmailConsent)
    const [businessEmailConsent, setBusinessEmailConsent] = useState<boolean | null>(props.startBlank ? null : user.preferences.businessEmailConsent)
    const [clubEmailInfoOpen, setClubEmailInfoOpen] = useState(false)
    const [businessEmailInfoOpen, setBusinessEmailInfoOpen] = useState(false)
    const [, saveMyClubConsent] = useMutation(EditAccountEmailPreferences_SetMyClubEmailConsent)
    const [, saveMyBusinessConsent] = useMutation(EditAccountEmailPreferences_SetMyBusinessEmailConsent)


    const save = async () => {
        if (clubEmailConsent == null || businessEmailConsent == null) {
            return props.onError("You must select an option for all preferences")
        }
        if (clubEmailConsent === user.preferences.clubEmailConsent && businessEmailConsent === user.preferences.businessEmailConsent) {
            return props.onSave(false)
        }
        try {
            loader.start()
            let r = await saveMyClubConsent({
                consent: clubEmailConsent
            })
            if ((r.error?.graphQLErrors.length ?? 0) > 0) {
                return props.onError(r.error!.graphQLErrors[0].message)
            }
            r = await saveMyBusinessConsent({
                consent: businessEmailConsent
            })
            if ((r.error?.graphQLErrors.length ?? 0) > 0) {
                return props.onError(r.error!.graphQLErrors[0].message)
            }
            props.onSave(true)
        } finally {
            loader.stop()
        }
    }

    return (
        <div>
            <div className="mb-4 inline-flex flex-col">
                <div className="inline-flex items-center my-2">
                    <div className="mx-2 flex items-stretch justify-stretch">
                        <button data-selected={clubEmailConsent === true || null} onClick={() => setClubEmailConsent(true)} className="border rounded-l p-2 text-green-500 hover:bg-green-500 hover:text-white data-[selected]:bg-green-500 data-[selected]:text-white">
                            <CheckIcon className="h-6 w-6" />
                        </button>
                        <button data-selected={clubEmailConsent === false || null} onClick={() => setClubEmailConsent(false)} className="border border-l-0 rounded-r p-2 text-red-500 hover:bg-red-500 hover:text-white data-[selected]:bg-red-500 data-[selected]:text-white">
                            <XMarkIcon className="h-6 w-6" />
                        </button>
                    </div>
                    <div className="text-gray-700 mb-2 inline-flex items-center">
                        Include my emails in club related mailings

                        <button className="ml-4" onClick={() => setClubEmailInfoOpen(true)}>
                            <InformationCircleIcon className="h-6 w-6" />
                        </button>
                    </div>

                </div>
                <div className="inline-flex items-center my-2">
                    <div className="mx-2 flex items-stretch justify-stretch">
                        <button data-selected={businessEmailConsent === true || null} onClick={() => setBusinessEmailConsent(true)} className="border rounded-l p-2 text-green-500 hover:bg-green-500 hover:text-white data-[selected]:bg-green-500 data-[selected]:text-white">
                            <CheckIcon className="h-6 w-6" />
                        </button>
                        <button data-selected={businessEmailConsent === false || null} onClick={() => setBusinessEmailConsent(false)} className="border border-l-0 rounded-r p-2 text-red-500 hover:bg-red-500 hover:text-white data-[selected]:bg-red-500 data-[selected]:text-white">
                            <XMarkIcon className="h-6 w-6" />
                        </button>
                    </div>
                    <div className="text-gray-700 mb-2 inline-flex items-center">
                        Share my emails with companies in the aerospace engineering industry

                        <button className="ml-4" onClick={() => setBusinessEmailInfoOpen(true)}>
                            <InformationCircleIcon className="h-6 w-6" />
                        </button>
                    </div>
                </div>
            </div>
            <div className="mb-4">
                <ButtonPrimary onClick={save}>
                    Save
                </ButtonPrimary>
            </div>

            <SimpleModal
                isOpen={clubEmailInfoOpen}
                onClose={() => setClubEmailInfoOpen(false)}
            >
                <div className="sm:flex sm:items-start">
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                        <h3 className="text-base font-semibold leading-6 text-gray-900" id="modal-title">Consent to Club Emails</h3>
                        <div className="mt-2">
                            <p className="text-sm text-gray-500">If you consent to club emails, we will email you updates to AIAA@UCF, such as GBM meetings, socials, and project updates. Emails in this category pertain only to AIAA@UCF operations and events. You will not receive emails for anything that does not pertain to AIAA@UCF.</p>
                        </div>
                    </div>
                </div>
            </SimpleModal>

            <SimpleModal
                isOpen={businessEmailInfoOpen}
                onClose={() => setBusinessEmailInfoOpen(false)}
            >
                <div className="sm:flex sm:items-start">
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                        <h3 className="text-base font-semibold leading-6 text-gray-900" id="modal-title">Consent to Business Emails</h3>
                        <div className="mt-2">
                            <p className="text-sm text-gray-500">If you consent to business emails, your email may be shared with businesses that want to reach the AIAA@UCF student body. As a part of our operations, we partner with various companies in the aerospace industry. Often, they want to target our set of members for things such as internship opportunities, mixers, etc. Consenting to business emails allows you to receive these communications.</p>
                        </div>
                    </div>
                </div>
            </SimpleModal>
        </div>
    )
}