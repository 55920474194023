import EditAccountEmailPreferences from "components/EditAccountEmailPreferences"
import EditAccountEmails from "components/EditAccountEmails"
import { graphql } from "generated"
import { constructLoginRedirectPath } from "lib/provideUrql"
import useDocumentTitle from "lib/useDocumentTitle"
import { Navigate, useLocation } from "react-router-dom"
import { toast } from "react-toastify"
import { useQuery } from "urql"

const GetMeDocument = graphql(`
    query AccountEmailsPage_GetMe {
        me {
            ...EditAccountEmails_UserFragment
            ...EditAccountEmailPreferences_UserFragment
        }
    }
`)

export default function AccountEmailsPage() {
    useDocumentTitle("Account Emails | AIAA@UCF Members")
    const { pathname } = useLocation()
    const [{ data }] = useQuery({ query: GetMeDocument })

    if (data?.me == null) {
        return <Navigate to={constructLoginRedirectPath(pathname)} />
    }

    return (
        <div className="md:ml-6">
            <h2 className="mb-2 text-xl">Email Addresses</h2>
            <EditAccountEmails user={data.me} />
            <h2 className="mt-8 mb-2 text-xl">Email Preferences</h2>
            <EditAccountEmailPreferences
                user={data.me}
                onSave={(s) => s ? toast.success("Email preferences saved") : toast.warn("Nothing was changed")}
                onError={(e) => toast.error(e)}
            />
        </div>
    )
}