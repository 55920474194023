//import homebannerBlurred from "../../../public/homebanner-blurred.jpg"
import { LinkButtonPrimary, LinkButtonPrimaryOutlined } from "components/Button";
import useDocumentTitle from "lib/useDocumentTitle";

export default function HomePage() {
    useDocumentTitle("AIAA@UCF Members")
    return (
        <div>
            {/*<Image
                    alt="AIAA Team"
                    src={homebannerBlurred}
                    quality={80}
                    fill
                    sizes="100vw"
                    style={{
                        objectFit: 'cover',
                        objectPosition: "50% 30%",
                        zIndex: -1
                    }}
                />*/}

            <div className="flex flex-col p-2 pt-10 md:p-20 items-center justify-center">
                <img alt="AIAA@UCF Logo" src="/aiaa-ucf-logo-black.png" />
                <h1 className="text-black text-2xl sm:text-4xl md:text-7xl text-center">Membership & Projects</h1>
                <div className="flex flex-col items-stretch md:flex-row my-10">
                    <LinkButtonPrimary to={`/membership`} className="m-4">
                        Manage Membership
                    </LinkButtonPrimary>
                    <LinkButtonPrimaryOutlined to={`/members`} className="m-4">
                        Search Members
                    </LinkButtonPrimaryOutlined>
                    <LinkButtonPrimaryOutlined to={`/projects`} className="m-4">
                        Search Projects
                    </LinkButtonPrimaryOutlined>
                </div>
            </div>
        </div>
    )
}
