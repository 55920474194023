import { QrScanner } from "@yudiel/react-qr-scanner";
import SimpleModal from "components/SimpleModal";
import useDocumentTitle from "lib/useDocumentTitle";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

export default function AttendanceSelfServiceScannerPage() {
    const navigate = useNavigate()
    useDocumentTitle("Attendance Scanner | AIAA@UCF Members")
    const [error, setError] = useState<string | null>(null)

    const onScan = (data: string) => {
        try {
            const url = new URL(data)
            if (url.origin !== window.location.origin || url.pathname !== "/attendance" || url.searchParams.get("c") == null) {
                setError("Code not recognized")
            }
            navigate(`/attendance?c=${url.searchParams.get("c")}`)
        } catch (_) {
            setError("Code not recognized")
        }
    }

    return (
        <div className="w-screen h-screen bg-black">
            <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
            <SimpleModal isOpen={error != null} onClose={() => setError(null)}>
                {error}
            </SimpleModal>
            {error == null && <QrScanner onError={() => {}} onDecode={onScan} />}
        </div>
    )
}