import EditAccountEmailPreferences from "components/EditAccountEmailPreferences";
import EditAccountEmails from "components/EditAccountEmails";
import { motion } from "framer-motion";
import { FragmentType, getFragmentData, graphql } from "generated";
import { toast } from "react-toastify";

export const OnboardingEmails_UserFragment = graphql(`
    fragment OnboardingEmails_UserFragment on User {
        id
        ...EditAccountEmails_UserFragment
        ...EditAccountEmailPreferences_UserFragment
    }
`)

type Props = {
    user: FragmentType<typeof OnboardingEmails_UserFragment>
    onNext(): void
}

export default function OnboardingEmails(props: Props) {
    const user = getFragmentData(OnboardingEmails_UserFragment, props.user)

    return (
        <motion.div className="flex flex-col justify-center items-center px-4 w-full" initial={{ opacity: 0, translateX: 100 }} animate={{ opacity: 100, translateX: 0 }} exit={{ translateX: -100, opacity: 0 }}>
            <p className="text-xl text-center my-6 font-bold">Your contact information</p>
            <div className="bg-white text-black p-6 rounded-lg w-full max-w-3xl">
                <EditAccountEmails
                    user={user}
                />
                <div className="my-12" />
                <EditAccountEmailPreferences
                    user={user}
                    startBlank={true}
                    onSave={props.onNext}
                    onError={(e) => toast.error(e)}
                />
            </div>
        </motion.div>
    )
}