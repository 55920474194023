import { useLoader } from "components/PageLoading"
import { graphql } from "generated"
import { Link, Navigate, useSearchParams } from "react-router-dom"
import { useState, useEffect } from "react"
import { useQuery } from "urql"

const GetMeDocument = graphql(`
    query PaymentCallbackPage_GetMe {
        me {
            preferences {
                onboarded
            }
        }
    }
`)

const GetIsMembershipPurchaseSessionCompletedDocument = graphql(`
query GetIsMembershipPurchaseSessionCompleted_PaymentCallbackPage($id: ID!) {
  isMembershipPurchaseSessionCompleted(id: $id)
}
`)

export default function PaymentCallbackPage() {
    const loader = useLoader()
    const [searchParams] = useSearchParams()
    const id = searchParams.get("id")
    const [timedOut, setTimedOut] = useState(false)
    const [{ data }, refetch] = useQuery({ query: GetIsMembershipPurchaseSessionCompletedDocument, variables: { id: id! }, pause: timedOut || id == null })
    const [{ data: meData }] = useQuery({ query: GetMeDocument })

    useEffect(() => {
        const tid = setInterval(() => refetch({ requestPolicy: 'network-only' }), 1000)
        return () => clearInterval(tid)
    }, [refetch])

    useEffect(() => {
        const tid = setTimeout(() => setTimedOut(true), 1000 * 15)
        return () => clearTimeout(tid)
    }, [])

    useEffect(() => {
        if (timedOut || id == null) {
            loader.stop()
        } else {
            loader.start()
        }
    })

    if (id == null) {
        return (
            <div className="flex justify-center">
                <div className="container mt-12">
                    <p>Invalid payment. <Link to="/membership" className="underline text-blue-400">Please try again</Link></p>
                </div>
            </div>
        )
    }

    if (data?.isMembershipPurchaseSessionCompleted === true) {
        loader.stop()
        if (meData?.me?.preferences.onboarded === false) {
            return <Navigate to={"/onboarding?from=membership"} />
        } else {
            return <Navigate to={"/membership"} />
        }
    }

    return (
        <div className="flex justify-center">
            <div className="container mt-12">
                {!timedOut && (
                    <p className="animate-pulse">Validating purchase...</p>
                )}
                {timedOut && (
                    <p>The payment hasn't gone through yet. Try checking back later. If you are still experiencing problems, email us at <a className="underline text-blue-400" href="mailto:membership@aiaaucf.com">membership@aiaaucf.com</a></p>
                )}
            </div>
        </div>
    )
}