import { LoggedInConditional_UserFragmentFragment } from "generated/graphql";
import { Link } from "react-router-dom";

type Props = {
    user: LoggedInConditional_UserFragmentFragment
}

export default function MobileAccountDropdown({ user }: Props) {
    return (
        <div>
            <span className="flex items-center justify-start ml-6">
                {user.givenName}
            </span>
            <Link to={`/users/${user.id}`}>
                <div className="pl-12 py-2 border-b-2 border-slate-400">
                    Profile
                </div>
            </Link>
            <Link to={`/myqr`}>
                <div className="pl-12 py-2 border-b-2 border-slate-400">
                    My QR
                </div>
            </Link>
            <Link to={`/account`}>
                <div className="pl-12 py-2 border-b-2 border-slate-400">
                    Account
                </div>
            </Link>
            <Link to={`/membership`}>
                <div className="pl-12 py-2 border-b-2 border-slate-400">
                    Membership
                </div>
            </Link>
            {(user.role?.scopes.length ?? 0) > 0 && (
                <Link to={`/admin`}>
                    <div className="pl-12 py-2 border-b-2 border-slate-400 text-blue-600 ">
                        Admin Tools
                    </div>
                </Link>
            )}
            <Link to={`/logout`}>
                <div className="pl-12 py-2 text-red-500">
                    Logout
                </div>
            </Link>
        </div>
    )
}

export const MobileSignInLink = () => <Link className="ml-6 hover:underline" to="/login">Sign In</Link>