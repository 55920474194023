import { FragmentType, getFragmentData, graphql } from "generated"
import { ReactNode, useCallback, useEffect, useState } from "react"
import { useLoader } from "./PageLoading"
import { LegacyMembershipValidationInfo, LegacyMembershipValidationResultFailReason } from "generated/graphql"
import { toast } from "react-toastify"
import phone from "phone"
import { useMutation } from "urql"
import { Link } from "react-router-dom"
import MembershipCard from "./MembershipCard"
import { ButtonPrimary } from "./Button"

export const LinkLegacyMembership_UserFragment = graphql(`
    fragment LinkLegacyMembership_UserFragment on User {
        ...MembershipCard_UserFragment
    }
`)

const LinkLegacyMembership_ValidateLegacyMembership = graphql(`
    mutation LinkLegacyMembership_ValidateLegacyMembership($validationInfo: LegacyMembershipValidationInfo!) {
        validateLegacyMembership(validationInfo: $validationInfo) {
            ... on LegacyMembershipValidationResultFail {
                reason
            }
            ... on LegacyMembershipValidationResultMoreInfo {
                supplyOrderNum
                supplyEmail
                supplyBillingName
                supplyPhone
                minRequiredValidators
            }
            ... on LegacyMembershipValidationResultSuccess {
                newMembership {
                    ...MembershipCard_MembershipFragment
                }
            }
        }
    }
`)


type Props = {
    user: FragmentType<typeof LinkLegacyMembership_UserFragment>
    backAction?: ReactNode
    successAction?: ReactNode
}

export default function LinkLegacyMembership(props: Props) {
    const loading = useLoader()
    const user = getFragmentData(LinkLegacyMembership_UserFragment, props.user)
    const [iterNum, setIterNum] = useState(0)
    const [orderNum, setOrderNum] = useState("")
    const [email, setEmail] = useState("")
    const [phoneNum, setPhoneNum] = useState("")
    const [billingName, setBillingName] = useState("")
    const [validateLegacyMembershipResult, validateLegacyMembership] = useMutation(LinkLegacyMembership_ValidateLegacyMembership)

    const validation = validateLegacyMembershipResult.data?.validateLegacyMembership

    const tryValidate = useCallback(async () => {

        const payload: LegacyMembershipValidationInfo = {
            orderNum: orderNum || null,
            billingName: billingName || null,
            phone: (phoneNum && phone(phoneNum).phoneNumber) || null,
            email: email || null
        }
        if (validation?.__typename === "LegacyMembershipValidationResultMoreInfo" && (
            (validation.supplyOrderNum && orderNum === "") ||
            (validation.supplyBillingName && billingName === "") ||
            (validation.supplyEmail && email === "") ||
            (validation.supplyPhone && phoneNum === "")
        )) {
            return toast.error("All fields are required")
        }

        try {
            loading.start()
            await validateLegacyMembership({
                validationInfo: payload
            }, { additionalTypenames: ["User", "Membership"] })
            setIterNum((i) => i + 1)
        } finally {
            loading.stop()
        }
    }, [validateLegacyMembership, validation, orderNum, billingName, phoneNum, email, loading])

    useEffect(() => {
        if (validateLegacyMembershipResult.data == null && validateLegacyMembershipResult.fetching === false) {
            tryValidate() //Initial verify
        }
    }, [validateLegacyMembershipResult, tryValidate])

    return (
        <div>
            {iterNum === 0 && (
                <>
                    <p className="animate-pulse text-2xl">Getting ready...</p>
                </>
            )}

            {validateLegacyMembershipResult.error?.graphQLErrors.some(e => e.extensions.code === "INVALID_STATE") && (
                <>
                    <h3 className="text-xl">You already have a membership linked to your account</h3>
                    <Link className="hover:underline text-blue-500" to="/account/membership">View Membership</Link>
                </>
            )}

            {validation?.__typename === "LegacyMembershipValidationResultMoreInfo" && (
                <>
                    <p className="mb-4 text-xl">
                        {iterNum <= 1 && "Let's find your order"}
                        {iterNum > 1 && "We need some more information to find your order"}
                    </p>
                    <div className="ml-4 mb-4">
                        {validation.supplyOrderNum && (
                            <div>

                                <div className="mb-4">
                                    <label className="block text-gray-700 text-l font-bold mb-2">
                                        Order Number
                                        <span className="block text-sm text-gray-500 italic font-normal">Look for your order confirmation email. It likely came from notifications@ecwid.com</span>
                                    </label>
                                    <input
                                        className="shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                        type="text"
                                        value={orderNum}
                                        onChange={e => setOrderNum(e.target.value)}
                                    />
                                </div>
                            </div>
                        )}

                        {validation.supplyPhone && (
                            <div>

                                <div className="mb-4">
                                    <label className="block text-gray-700 text-l font-bold mb-2">
                                        Phone Number
                                        <span className="block text-sm text-gray-500 italic font-normal">Enter the phone number you used with your order.</span>
                                    </label>
                                    <input
                                        className="shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                        type="tel"
                                        value={phoneNum}
                                        onChange={e => setPhoneNum(e.target.value)}
                                    />
                                </div>
                            </div>
                        )}

                        {validation.supplyEmail && (
                            <div>

                                <div className="mb-4">
                                    <label className="block text-gray-700 text-l font-bold mb-2">
                                        Email
                                        <span className="block text-sm text-gray-500 italic font-normal">Enter the email you used with your order.</span>
                                    </label>
                                    <input
                                        className="shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                        type="text"
                                        value={email}
                                        onChange={e => setEmail(e.target.value)}
                                    />
                                </div>
                            </div>
                        )}

                        {validation.supplyBillingName && (
                            <div>

                                <div className="mb-4">
                                    <label className="block text-gray-700 text-l font-bold mb-2">
                                        Billing Name
                                        <span className="block text-sm text-gray-500 italic font-normal">Enter the billing name EXACTLY as it was entered on your order.</span>
                                    </label>
                                    <input
                                        className="shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                        type="text"
                                        value={billingName}
                                        onChange={e => setBillingName(e.target.value)}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                    <ButtonPrimary onClick={tryValidate}>Submit</ButtonPrimary>
                </>
            )}

            {validation?.__typename === "LegacyMembershipValidationResultFail" && (
                <>
                    <p className="mb-4 text-xl">
                        We couldn't link your order
                    </p>
                    <p className="mb-4 text-l text-red-500">
                        {validation.reason === LegacyMembershipValidationResultFailReason.InvalidOrderNum && "The order number you entered is invalid"}
                        {validation.reason === LegacyMembershipValidationResultFailReason.AlreadyExpired && "The membership purchased in this order has already expired"}
                        {validation.reason === LegacyMembershipValidationResultFailReason.AlreadyRedeemed && (
                            <>This order has already been linked to a user. Email us at <a className="hover:underline text-blue-500" href="mailto:membership@aiaaucf.com">membership@aiaaucf.com</a> if this was not you.</>
                        )}
                        {validation.reason === LegacyMembershipValidationResultFailReason.NoMatch && (
                            <>The information you entered does not match any order. Ensure everything is entered EXACTLY as it was on the original order. Email us at <a className="hover:underline text-blue-500" href="mailto:membership@aiaaucf.com">membership@aiaaucf.com</a> if you need further assistance.</>
                        )}
                    </p>
                    {props.backAction}
                </>
            )}

            {validation?.__typename === "LegacyMembershipValidationResultSuccess" && (
                <>
                    <p className="mb-4 text-xl text-green-600">
                        Your order was linked!
                    </p>
                    <p className="my-4">Here are the details of your membership:</p>
                    <MembershipCard user={user} membership={validation.newMembership!} />
                    <div>
                        {props.successAction}
                    </div>
                </>
            )}
        </div>
    )
}