import { FragmentType, getFragmentData, graphql } from "generated"
import React, { useState } from "react"
import { ButtonDanger, ButtonDisabled, ButtonSuccess } from "./Button"
import { useMutation } from "urql";
import { useLoader } from "./PageLoading";
import SimpleModal from "./SimpleModal";
import { toast } from "react-toastify";

export const EditAccountEmails_UserFragment = graphql(`
    fragment EditAccountEmails_UserFragment on User {
        id
        emails {
            email
            confirmed
        }
    }
`)

export const EditAccountProfile_AddMyEmail = graphql(`
    mutation EditAccountProfile_AddMyEmail($newEmail: String!) {
        addMyEmail(newEmail: $newEmail) {
            id
            emails {
                email
                confirmed
            }
        }
    }
`)

export const EditAccountProfile_RemoveMyEmail = graphql(`
    mutation EditAccountProfile_RemoveMyEmail($email: String!) {
        removeMyEmail(email: $email) {
            id
            emails {
                email
                confirmed
            }
        }
    }
`)

type Props = {
    user: FragmentType<typeof EditAccountEmails_UserFragment>
}

export default function EditAccountEmails(props: Props) {
    const loader = useLoader()
    const [addingNew, setAddingNew] = useState<string | null>(null)
    const [removingEmail, setRemovingEmail] = useState<string | null>(null)
    const [, addEmail] = useMutation(EditAccountProfile_AddMyEmail)
    const [, removeEmail] = useMutation(EditAccountProfile_RemoveMyEmail)
    const user = getFragmentData(EditAccountEmails_UserFragment, props.user)

    const perforlgelete = async () => {
        try {
            loader.start()
            const email = removingEmail!
            setRemovingEmail(null)
            const r = await removeEmail({
                email: email
            })
            if ((r.error?.graphQLErrors.length ?? 0) > 0) {
                return toast.error(r.error!.graphQLErrors[0].message)
            }
            toast.success("Email removed")
        } finally {
            loader.stop()
        }
    }

    const performAdd = async () => {
        try {
            loader.start()
            const r = await addEmail({
                newEmail: addingNew!
            })
            if ((r.error?.graphQLErrors.length ?? 0) > 0) {
                return toast.error(r.error!.graphQLErrors[0].message)
            }
            toast.success("Email added. Check your inbox for a confirmation link.")
            setAddingNew(null)
        } finally {
            loader.stop()
        }
    }

    return (
        <div className="max-w-3xl">
            <div className="grid grid-cols-6 border border-b-0 border-gray-400">
                {user.emails?.map(e => (
                    <React.Fragment key={e.email}>
                        <div className="col-span-6 lg:col-span-3 flex items-center justify-center text-center truncate border-b lg:border-r border-gray-400 py-1 px-1">{e.email}</div>
                        {e.confirmed && <div className="col-span-3 lg:col-span-1 flex items-center truncate justify-center text-center border-b-4 lg:border-b border-r border-gray-400 py-1 px-1">Confirmed</div>}
                        {!e.confirmed && <div className="col-span-3 lg:col-span-1 flex items-center truncate justify-center text-center border-b-4 lg:border-b border-r border-gray-400 py-1 px-1 text-red-500">Not Confirmed</div>}
                        <div className="col-span-3 lg:col-span-2 flex items-center justify-center text-center border-b-4 lg:border-b border-gray-400 py-1 px-1">
                            {user.emails!.length > 1 && (
                                <ButtonDanger onClick={() => setRemovingEmail(e.email)}>Delete</ButtonDanger>
                            )}
                            {user.emails!.length <= 1 && (
                                <ButtonDisabled>Delete</ButtonDisabled>
                            )}
                        </div>
                    </React.Fragment>
                ))}
                {addingNew != null && (
                    <>
                        <div className="col-span-4 flex items-center justify-center text-center truncate border-b border-r border-gray-400 py-1 px-6">
                            <input
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                type="text"
                                autoFocus
                                value={addingNew}
                                onChange={(e) => setAddingNew(e.target.value)}
                            />
                        </div>
                        <div className="col-span-2 flex items-center justify-center text-center border-b border-gray-400 py-1">
                            <ButtonSuccess onClick={performAdd}>Add</ButtonSuccess>
                        </div>
                    </>
                )}
            </div>

            {addingNew == null && (
                <div className="mt-6 flex justify-end">
                    <ButtonSuccess onClick={() => setAddingNew("")}>New Email</ButtonSuccess>
                </div>
            )}

            <SimpleModal
                isOpen={removingEmail != null}
                onClose={() => setRemovingEmail(null)}
                actions={
                    <ButtonDanger onClick={perforlgelete}>Delete Email</ButtonDanger>
                }
                closeText="Cancel"
            >
                <div className="sm:flex sm:items-start">
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                        <h3 className="text-base font-semibold leading-6 text-gray-900" id="modal-title">Delete email</h3>
                        <div className="mt-2">
                            <p className="text-sm text-gray-500">Are you sure you want to delete the email {removingEmail}? This email will no longer be associated with your AIAA@UCF account, will not recieve club emails, and cannot be used for payment recovery.</p>
                        </div>
                    </div>
                </div>
            </SimpleModal>

        </div>
    )
}