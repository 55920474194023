import Cookies from "js-cookie";
import { LoaderFunction } from "react-router-dom";

const apiLoginPath = "/api/ms/login"

export const LoginHandler: LoaderFunction = ({ request }) => {
    const url = new URL(request.url)
    const error = url.searchParams.get("error")
    if (error != null) {
        throw new Error(error)
    }
    const redirectPath = url.searchParams.get("redirect-to")
    if (redirectPath != null) {
        Cookies.set("login-redirect-to", redirectPath, {
            expires: 1/24,
            sameSite: "Lax"
        })
    }
    window.location.assign(apiLoginPath)
    return null
}