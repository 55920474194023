import { ChangeEventHandler, ReactEventHandler, useMemo, useRef, useState } from "react"
import { ButtonPrimary } from "./Button"
import ReactCrop, { makeAspectCrop, Crop, PixelCrop, PercentCrop } from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import { useMutation } from "urql"
import { graphql } from "generated"
import { useLoader } from "./PageLoading"

const UploadProfilePicture_SetMyProfilePictureMutation = graphql(`
mutation UploadProfilePicture_SetMyProfilePictureMutation($profilePicture: Upload!) {
  setMyProfilePicture(profilePicture: $profilePicture) {
    id
    profilePicture
  }
}`)

type Props = {
    onSave(): void
    onError(err: string): void
}

export default function UploadProfilePicture(props: Props) {
    const [, setMyProfilePicture] = useMutation(UploadProfilePicture_SetMyProfilePictureMutation)
    const loader = useLoader()
    const [activeFile, setActiveFile] = useState<File>()
    const [crop, setCrop] = useState<Crop>()
    const inputRef = useRef<HTMLInputElement>(null)
    const previewCanvasRef = useRef<HTMLCanvasElement>(null)

    const activeFileUrl = useMemo(() => activeFile && URL.createObjectURL(activeFile), [activeFile])

    const onSelectFile: ChangeEventHandler<HTMLInputElement> = (e) => {
        if (e.target.files != null && e.target.files.length === 1) {
            setActiveFile(e.target.files.item(0)!)
        }
    }

    const onImageLoad: ReactEventHandler<HTMLImageElement> = (e) => {
        const { naturalWidth: width, naturalHeight: height } = e.currentTarget

        const crop = makeAspectCrop({ unit: "%", width: 100, height: 100 }, 1, width, height)
        onChangeCrop(null, crop)
    }

    const onChangeCrop = (_: PixelCrop | null, crop: PercentCrop) => {
        const img = new Image()
        img.src = activeFileUrl!
        img.onload = () => canvasPreview(img, previewCanvasRef.current!, crop)
        setCrop(crop)
    }

    const onSave = () => {
        previewCanvasRef.current?.toBlob(blob => {
          if (blob != null) {
            loader.start()
            setMyProfilePicture({ profilePicture: blob as File }).then(r => {
                if ((r.error?.graphQLErrors.length ?? 0) > 0) {
                    return props.onError(r.error!.graphQLErrors[0].message)
                }
                props.onSave()
            }).finally(() => loader.stop())
          }
        })
    }

    return (
        <div>
            {activeFile == null && (
                <div>
                    <p>Your AIAA@UCF Profile picture should be a picture of <b>you</b>. It is used to help identify who you are, and may be seen publicly. It should not be an avatar or character.</p>
                    <input ref={inputRef} type="file" accept="image/png,image/jpeg,image/webp" style={{ display: "none" }} onChange={onSelectFile} />
                    <ButtonPrimary onClick={() => inputRef.current?.click()}>Select Image</ButtonPrimary>
                </div>
            )}

            {activeFile != null && (
                <div>
                    <ReactCrop
                        crop={crop}
                        onChange={onChangeCrop}
                        keepSelection={true}
                        aspect={1}
                        circularCrop={true}
                    >
                        <picture>
                            <img src={activeFileUrl!} alt="Uploaded" onLoad={onImageLoad} />
                        </picture>
                    </ReactCrop>
                    <div>
                        <canvas className="rounded-full" ref={previewCanvasRef} />
                    </div>
                    <ButtonPrimary className="mt-6" onClick={onSave}>Save</ButtonPrimary>
                </div>
            )}
        </div>
    )
}


export async function canvasPreview(
    image: HTMLImageElement,
    canvas: HTMLCanvasElement,
    crop: PercentCrop
) {
    const ctx = canvas.getContext('2d')!

    canvas.width = 256
    canvas.height = 256
    canvas.style.width = "256px"
    canvas.style.height = "256px"

    ctx.drawImage(
        image,
        crop.x/100 * image.width,
        crop.y/100 * image.height,
        crop.width/100 * image.width,
        crop.height/100 * image.height,
        0,
        0,
        256,
        256
    )
}
