import Cookies from "js-cookie";
import { LoaderFunction, redirect } from "react-router-dom";

const defaultRedirect = "/account"

export const LoginSuccessHandler: LoaderFunction = ({ request }) => {
    let redirectUrl = defaultRedirect
    const redirectCookie = Cookies.get("login-redirect-to")
    if (redirectCookie != null) {
        let url = new URL(request.url)
        url = new URL(redirectCookie, url.origin)
        redirectUrl = url.toString()
        Cookies.remove("login-redirect-to")
    }

    return redirect(redirectUrl)
}