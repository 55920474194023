import { FragmentType, getFragmentData, graphql } from "generated"
import ProfilePicture from "./ProfilePicture"
import MembershipCard from "./MembershipCard"
import { DateTime } from "luxon"
import { ButtonDisabled, ButtonPrimary, ButtonSuccess } from "./Button"
import { useState } from "react"
import SimpleModal from "./SimpleModal"
import { ManageUser_UserFragmentFragment } from "generated/graphql"
import { useMutation } from "urql"
import { toast } from "react-toastify"
import { useLoader } from "./PageLoading"

const ManageUser_UserFragment = graphql(`
    fragment ManageUser_UserFragment on User {
        id
        fullName
        givenName
        ucfEmail
        earliestMembershipTimestamp
        hasDemographics
        membership {
            ...MembershipCard_MembershipFragment
        }
        preferences {
            onboarded
        }

        ...ProfilePicture_UserFragment
        ...MembershipCard_UserFragment
    }
`)

type Props = {
    user: FragmentType<typeof ManageUser_UserFragment>
    editable: boolean
}

export default function ManageUser(props: Props) {
    const user = getFragmentData(ManageUser_UserFragment, props.user)

    return (
        <div className="w-full">
            <h1 className="text-3xl">{user.fullName}</h1>
            <div className="flex flex-col items-center sm:items-start sm:flex-row flex-wrap mt-4">
                <ProfilePicture user={user} />
                <div className="sm:ml-6 mt-6 flex flex-col">
                    <table className="table-fixed w-full max-w-xl overflow-hidden border-separate border-spacing-x-6 border-spacing-y-1">
                        <tbody>
                            <tr>
                                <th align="left">Full Name:</th>
                                <td className="text-clip">{user.fullName}</td>
                            </tr>
                            <tr>
                                <th align="left">Given Name:</th>
                                <td className="text-clip">{user.givenName}</td>
                            </tr>
                            <tr>
                                <th align="left">UCF Email:</th>
                                <td className="text-clip">{user.ucfEmail}</td>
                            </tr>
                            <tr>
                                <th align="left">Member since:</th>
                                <td className="text-clip">{user.earliestMembershipTimestamp != null ? DateTime.fromSeconds(user.earliestMembershipTimestamp).toLocaleString(DateTime.DATE_FULL) : "N/A"}</td>
                            </tr>
                            <tr>
                                <th align="left">Has completed onboarding:</th>
                                <td className="text-clip">{user.preferences.onboarded ? "Yes" : "No"}</td>
                            </tr>
                            <tr>
                                <th align="left">Has Demographics</th>
                                <td className="text-clip">
                                    {user.hasDemographics === true && "Yes"}
                                    {user.hasDemographics === false && "No"}
                                    {user.hasDemographics == null && "N/A"}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="sm:ml-6 mt-6 flex flex-col">
                    <h2 className="text-xl">Current Membership:</h2>
                    {user.membership != null && (
                        <MembershipCard user={user} membership={user.membership} />
                    )}
                    {user.membership == null && (
                        <span className="italic">No membership</span>
                    )}
                </div>
            </div>
            {props.editable && (
                <div className="mt-6">
                    <h2 className="text-xl font-bold">Management tools</h2>
                    <div className="flex sm:inline-flex flex-col mt-2">
                        <EditNameButton user={user} />
                        <ClearProfilePictureButton user={user} />
                        {user.preferences.onboarded === true && <ForceReOnboardButton user={user} />}
                        {user.preferences.onboarded === false && <ButtonDisabled className="my-1">Force Re-Onboard</ButtonDisabled>}
                        {user.membership == null && <GrantMembershipButton user={user} />}
                        {user.membership != null && <ButtonDisabled className="my-1">Grant Membership</ButtonDisabled>}
                        {user.membership == null && <ButtonDisabled className="my-1">Revoke Membership</ButtonDisabled>}
                        {user.membership != null && <RevokeMembershipButton user={user} />}
                    </div>
                </div>
            )}
        </div>
    )
}

type ManagementButtonProps = {
    user: ManageUser_UserFragmentFragment
}

export const ManageUser_SetNames = graphql(`
    mutation ManageUser_SetNames($userId: ID!, $newGivenName: String!, $newFullName: String!) {
        setGivenName(userId: $userId, newGivenName: $newGivenName) {
            id
        }
        setFullName(userId: $userId, newFullName: $newFullName) {
            id
        }
    }
`)

function EditNameButton({ user }: ManagementButtonProps) {
    const loader = useLoader()
    const [open, setOpen] = useState(false)
    const [fullName, setFullName] = useState(user.fullName)
    const [givenName, setGivenName] = useState(user.givenName)
    const [, setNames] = useMutation(ManageUser_SetNames)

    const save = async () => {
        if (givenName.length === 0) {
            return toast.error("You must enter a given name")
        }
        if (fullName.length === 0) {
            return toast.error("You must enter a full name")
        }
        if (fullName === user.fullName && givenName === user.givenName) {
            setOpen(false)
            return toast.warn("Nothing was changed")
        }
        try {
            loader.start()
            let r = await setNames({
                userId: user.id,
                newFullName: fullName,
                newGivenName: givenName
            })
            if ((r.error?.graphQLErrors.length ?? 0) > 0) {
                return toast.error(r.error!.graphQLErrors[0].message)
            }
            setOpen(false)
            toast.success("User profile updated")
        } finally {
            loader.stop()
        }
    }

    return (
        <>
            <ButtonPrimary className="my-1" onClick={() => setOpen(true)}>Edit Name</ButtonPrimary>

            <SimpleModal
                isOpen={open}
                onClose={() => setOpen(false)}
                actions={<ButtonSuccess onClick={save}>Save</ButtonSuccess>}
            >
                <div className="sm:flex sm:items-start">
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                        <h3 className="text-base font-semibold leading-6 text-gray-900" id="modal-title">Edit Name</h3>
                        <div className="mt-2">
                            <div className="mb-4">
                                <label className="block text-gray-700 text-sm font-bold mb-2">
                                    Full Name
                                </label>
                                <input
                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    type="text"
                                    value={fullName}
                                    onChange={(e) => setFullName(e.target.value)}
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block text-gray-700 text-sm font-bold mb-2">
                                    Given Name
                                </label>
                                <input
                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    type="text"
                                    value={givenName}
                                    onChange={(e) => setGivenName(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </SimpleModal>
        </>
    )
}

export const ManageUser_ClearProfilePicture = graphql(`
    mutation ManageUser_ClearProfilePicture($userId: ID!) {
        clearProfilePicture(userId: $userId) {
            id
        }
    }
`)

function ClearProfilePictureButton({ user }: ManagementButtonProps) {
    const loader = useLoader()
    const [open, setOpen] = useState(false)
    const [, clearProfilePicture] = useMutation(ManageUser_ClearProfilePicture)

    const save = async () => {
        try {
            loader.start()
            let r = await clearProfilePicture({
                userId: user.id
            })
            if ((r.error?.graphQLErrors.length ?? 0) > 0) {
                return toast.error(r.error!.graphQLErrors[0].message)
            }
            setOpen(false)
            toast.success("User profile updated")
        } finally {
            loader.stop()
        }
    }

    return (
        <>
            <ButtonPrimary className="my-1" onClick={() => setOpen(true)}>Clear Profile Picture</ButtonPrimary>

            <SimpleModal
                isOpen={open}
                onClose={() => setOpen(false)}
                actions={<ButtonSuccess onClick={save}>Clear Profile Picture</ButtonSuccess>}
            >
                <div className="sm:flex sm:items-start">
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                        <h3 className="text-base font-semibold leading-6 text-gray-900" id="modal-title">Clear profile picture</h3>
                        <div className="mt-2">
                            <p className="text-sm text-gray-500">Are you sure you want to clear this user's profile picture? It will reset to one of the default profile pictures. This should only be done if the user has uploaded an inappropriate picture.</p>
                        </div>
                    </div>
                </div>
            </SimpleModal>
        </>
    )
}

export const ManageUser_ForceReOnboard = graphql(`
    mutation ManageUser_ForceReOnboard($userId: ID!) {
        forceReOnboard(userId: $userId) {
            id
        }
    }
`)

function ForceReOnboardButton({ user }: ManagementButtonProps) {
    const loader = useLoader()
    const [open, setOpen] = useState(false)
    const [, forceReOnboard] = useMutation(ManageUser_ForceReOnboard)

    const save = async () => {
        try {
            loader.start()
            let r = await forceReOnboard({
                userId: user.id
            })
            if ((r.error?.graphQLErrors.length ?? 0) > 0) {
                return toast.error(r.error!.graphQLErrors[0].message)
            }
            setOpen(false)
            toast.success("User profile updated")
        } finally {
            loader.stop()
        }
    }

    return (
        <>
            <ButtonPrimary className="my-1" onClick={() => setOpen(true)}>Force Re-Onboard</ButtonPrimary>

            <SimpleModal
                isOpen={open}
                onClose={() => setOpen(false)}
                actions={<ButtonSuccess onClick={save}>Force Re-Onboarding</ButtonSuccess>}
            >
                <div className="sm:flex sm:items-start">
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                        <h3 className="text-base font-semibold leading-6 text-gray-900" id="modal-title">Force Re-Onboarding</h3>
                        <div className="mt-2">
                            <p className="text-sm text-gray-500">Are you sure you want to force onboarding for this user? This will prompt the user to go through the onboarding process next time they log in.</p>
                        </div>
                    </div>
                </div>
            </SimpleModal>
        </>
    )
}

export const ManageUser_GrantMembership = graphql(`
    mutation ManageUser_GrantMembership($userId: ID!, $timestamp: Int!) {
        grantMembership(userId: $userId, timestamp: $timestamp) {
            id
        }
    }
`)

function GrantMembershipButton({ user }: ManagementButtonProps) {
    const loader = useLoader()
    const [open, setOpen] = useState(false)
    const [timestamp, setTimestamp] = useState((): string => {
        let date = new Date()
        date.setMinutes(date.getMinutes() - date.getTimezoneOffset())
        return date.toISOString().slice(0, 16)
    })
    const [, grantMembership] = useMutation(ManageUser_GrantMembership)

    const save = async () => {
        const date = new Date(timestamp)
        try {
            loader.start()
            let r = await grantMembership({
                userId: user.id,
                timestamp: Math.floor(date.getTime()/1000)
            })
            if ((r.error?.graphQLErrors.length ?? 0) > 0) {
                return toast.error(r.error!.graphQLErrors[0].message)
            }
            setOpen(false)
            toast.success("Membership granted")
        } finally {
            loader.stop()
        }
    }

    return (
        <>
            <ButtonPrimary className="my-1" onClick={() => setOpen(true)}>Grant Membership</ButtonPrimary>

            <SimpleModal
                isOpen={open}
                onClose={() => setOpen(false)}
                actions={<ButtonSuccess onClick={save}>Grant Membership</ButtonSuccess>}
            >
                <div className="sm:flex sm:items-start">
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                        <h3 className="text-base font-semibold leading-6 text-gray-900" id="modal-title">Grant Membership</h3>
                        <div className="mt-2">
                            <p className="text-sm text-gray-500">Are you sure you want to grant a membership for this user? They will be given a new membership as though they had just purchased it. They will receive new member emails as though they had purchased a membership.</p>
                            <input
                                className="shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                type="datetime-local"
                                value={timestamp}
                                onChange={e => {
                                    setTimestamp(e.target.value)
                                }}
                            />
                        </div>
                    </div>
                </div>
            </SimpleModal>
        </>
    )
}

export const ManageUser_RevokeMembership = graphql(`
    mutation ManageUser_RevokeMembership($userId: ID!) {
        revokeMembership(userId: $userId) {
            id
        }
    }
`)

function RevokeMembershipButton({ user }: ManagementButtonProps) {
    const loader = useLoader()
    const [open, setOpen] = useState(false)
    const [, revokeMembership] = useMutation(ManageUser_RevokeMembership)

    const save = async () => {
        try {
            loader.start()
            let r = await revokeMembership({
                userId: user.id,
            })
            if ((r.error?.graphQLErrors.length ?? 0) > 0) {
                return toast.error(r.error!.graphQLErrors[0].message)
            }
            setOpen(false)
            toast.success("Membership revoked")
        } finally {
            loader.stop()
        }
    }

    return (
        <>
            <ButtonPrimary className="my-1" onClick={() => setOpen(true)}>Revoke Membership</ButtonPrimary>

            <SimpleModal
                isOpen={open}
                onClose={() => setOpen(false)}
                actions={<ButtonSuccess onClick={save}>Revoke Membership</ButtonSuccess>}
            >
                <div className="sm:flex sm:items-start">
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                        <h3 className="text-base font-semibold leading-6 text-gray-900" id="modal-title">Revoke Membership</h3>
                        <div className="mt-2">
                            <p className="text-sm text-gray-500">Are you sure you want to Revoke membership from this user? Their membership will be cancelled immediately. They will <b>not</b> receive any emails regarding this action.</p>
                        </div>
                    </div>
                </div>
            </SimpleModal>
        </>
    )
}