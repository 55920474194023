import { Suspense } from "react"
import ForceOnboarding from "components/ForceOnboarding";
import PageLoading from "components/PageLoading";
import { Outlet } from "react-router-dom";

export default function Root() {
    return (
        <PageLoading>
            <ForceOnboarding />
            <Suspense>
                <Outlet />
            </Suspense>
        </PageLoading>
    )
}