import { graphql } from "generated";
import { urqlClient } from "lib/provideUrql";
import { LoaderFunction, redirect } from "react-router-dom";

const PurchaseMembershipHandler_CreateMembershipPurchaseSession = graphql(`
    mutation PurchaseMembershipHandler_CreateMembershipPurchaseSession {
        createMembershipPurchaseSession {
            id
            paymentLink
        }
    }
`)
export const PurchaseMembershipHandler: LoaderFunction = async () => {
    const r = await urqlClient.mutation(PurchaseMembershipHandler_CreateMembershipPurchaseSession, {})

    if (r.data?.createMembershipPurchaseSession?.paymentLink != null) {
        window.location.assign(r.data.createMembershipPurchaseSession.paymentLink)
        return null
    }

    //TODO Proper error handling here
    return redirect("/account/membership")
}