import { graphql } from "generated";
import useDocumentTitle from "lib/useDocumentTitle";
import { useEffect } from "react";
import QRCode from "react-qr-code";
import { useParams } from "react-router-dom";
import { useQuery } from "urql";

const GenerateLiveJwtQrDocument = graphql(`
    query GenerateLiveJwtQr($projectSessionId: ID!) {
        generateLiveJwtQr(subjectType: "ProjectSession", subjectId: $projectSessionId)
    }
`)

export default function AdminProjectAttendanceLiveQrPage() {
    useDocumentTitle("Admin Project Attendance LiveQR | AIAA@UCF Members")
    const { projectSessionId } = useParams()
    const [result, executeQuery] = useQuery({ query: GenerateLiveJwtQrDocument, variables: { projectSessionId: projectSessionId! } })

    useEffect(() => {
        if (!result.fetching) {
            const id = setTimeout(() => executeQuery({ requestPolicy: 'network-only' }), 5000);
            return () => clearTimeout(id);
        }
    }, [result.fetching, executeQuery])

    const jwt = result.data?.generateLiveJwtQr ?? ""

    return (
        <div>
            <div className="w-full bg-aiaablue flex items-center px-8 py-3 justify-between text-white">
                <div className="flex items-center justify-evenly">
                    <img src="/aiaa-logo-white.png" alt="AIAA@UCF White Logo" height={100} width={82} />
                </div>
                <div className="w-full text-4xl text-center">Attendance</div> {/* TODO Show name of project */}
            </div>
            <div className="flex justify-center items-center">
                <QRCode value={`${window.location.origin}/attendance?c=${jwt}`} className="w-full max-w-[80vw] h-auto max-h-[80vh] aspect-square p-8" />
            </div>
        </div>
    )
}