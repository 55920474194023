import { FragmentType, getFragmentData, graphql } from "generated"
import ProjectCard from "./ProjectCard"

const AllProjectsList_ProjectFragment = graphql(`
    fragment AllProjectsList_ProjectFragment on Project {
        ...ProjectCard_ProjectFragment
    }
`)

type Props = {
    projects: FragmentType<typeof AllProjectsList_ProjectFragment>[]
    baseComponent: Parameters<typeof ProjectCard>[0]['baseComponent']
}

export default function AllProjectsList(props: Props) {
    const projects = getFragmentData(AllProjectsList_ProjectFragment, props.projects)

    return (
        <div className="grid grid-cols-[repeat(auto-fill,_minmax(200px,_1fr))]">
            {projects.map(p => (
                <ProjectCard project={p} baseComponent={props.baseComponent} />
            ))}
        </div>
    )
}