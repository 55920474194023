import AdminProjectNavigationTopbar from "components/AdminProjectNavigationTopbar";
import { graphql } from "generated";
import { Outlet, useParams } from "react-router-dom";
import { useQuery } from "urql";

const GetProjectAndSessionDocument = graphql(`
    query GetProjectAndSession($projectId: ID!, $projectSessionId: ID!) {
        project(id: $projectId) {
            id
            name
            session(id: $projectSessionId) {
                id
                name
            }
        }
    }
`)

export default function AdminProjectLayout() {
    const { projectId, projectSessionId } = useParams()
    const [result] = useQuery({ query: GetProjectAndSessionDocument, variables: { projectId: projectId!, projectSessionId: projectSessionId! } })

    if (result.data?.project?.session == null) {
        throw new Response("Not Found", { status: 404 })
    }

    return (
        <div>
            <div>
                <h2 className="text-xl ml-4">{result.data.project.name} {result.data.project.session.name}</h2>
                <AdminProjectNavigationTopbar projectId={projectId!} projectSessionId={projectSessionId!} />
            </div>
            <div className="px-4 pt-4">
                <Outlet />
            </div>
        </div>
    )
}