import { FragmentType, getFragmentData, graphql } from "generated"

export const ClickableProfilePicture_UserFragment = graphql(`
    fragment ClickableProfilePicture_UserFragment on User {
        id
        profilePicture
    }
`)

type Props = {
    user: FragmentType<typeof ClickableProfilePicture_UserFragment>
    onClick?(): void
}

export default function ClickableProfilePicture(props: Props) {
    const user = getFragmentData(ClickableProfilePicture_UserFragment, props.user)
    return (
        <div>
            <img
                alt="Profile"
                src={user.profilePicture}
                width={128}
                height={128}
                className="rounded-full hover:brightness-50 cursor-pointer border-black border-2"
                onClick={props.onClick} />
        </div>
    )
}