import ManageUser from "components/ManageUser"
import { graphql } from "generated"
import { constructLoginRedirectPath } from "lib/provideUrql"
import useDocumentTitle from "lib/useDocumentTitle"
import { Navigate, useLocation, useParams } from "react-router-dom"
import { toast } from "react-toastify"
import { useQuery } from "urql"

const GetMeDocument = graphql(`
    query AdminUserPage_GetMe {
        me {
            role {
                id
                scopes
            }
        }
    }
`)

const GetUserDocument = graphql(`
    query AdminUsersPage_GetUser($userId: ID!) {
        user(id: $userId) {
            ...ManageUser_UserFragment
        }
    }
`)

export default function AdminUserPage() {
    useDocumentTitle("Manage User | AIAA@UCF Members")
    const { userId } = useParams()
    const { pathname } = useLocation()
    const [{ data: meData }] = useQuery({ query: GetMeDocument })
    const [{ data: userData }] = useQuery({ query: GetUserDocument, variables: { userId: userId! }, pause: meData?.me == null })

    if (meData?.me == null) {
        return <Navigate to={constructLoginRedirectPath(pathname)} />
    }

    if (!meData.me.role?.scopes?.includes("view-users")) {
        toast.error("You don't have permission to access this page", {
            toastId: "nopagepermission"
        })
        return <Navigate to="/" />
    }

    if (userData?.user == null) {
        throw new Response("Not Found", { status: 404 })
    }

    return (
        <div className="ml-6">
            <ManageUser user={userData.user} editable={meData.me.role?.scopes?.includes("manage-users")} />
        </div>
    )
}