import useDocumentTitle from "lib/useDocumentTitle"

export default function AdminProjectMembersPage() {
    useDocumentTitle("Admin Project Members | AIAA@UCF Members")

    return (
        <div>
            <div className="flex justify-center mt-6">
                <div className="container flex-col flex justify-center items-center p-6 bg-yellow-300 border-4 border-yellow-600 rounded-lg text-yellow-900">
                    <h1 className="text-3xl">This page is under construction</h1>
                    <p className="mt-8">Confirmed members editing not available yet</p>
                </div>
            </div>
        </div>
    )
}