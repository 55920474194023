import { LoggedInConditional_UserFragmentFragment } from "generated/graphql";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { Link } from "react-router-dom";

type Props = {
    user: LoggedInConditional_UserFragmentFragment
}

export default function AccountDropdown({ user }: Props) {
    return (
        <div>
            <span className="peer/dropdown cursor-pointer flex items-center justify-end min-w-[90px]">
                {user.givenName}
                <ChevronDownIcon className="h-5 w-5" />
            </span>
            <div className="invisible peer-hover/dropdown:visible hover:visible absolute bg-white border-2 border-slate-400 rounded text-black">
                <Link to={`/users/${user.id}`}>
                    <div className="px-3 py-2 border-b-2 border-slate-400 hover:bg-slate-200">
                        Profile
                    </div>
                </Link>
                <Link to={`/myqr`}>
                    <div className="px-3 py-2 border-b-2 border-slate-400 hover:bg-slate-200">
                        My QR
                    </div>
                </Link>
                <Link to={`/account/profile`}>
                    <div className="px-3 py-2 border-b-2 border-slate-400 hover:bg-slate-200">
                        Account
                    </div>
                </Link>
                <Link to={`/account/membership`}>
                    <div className="px-3 py-2 border-b-2 border-slate-400 hover:bg-slate-200">
                        Membership
                    </div>
                </Link>
                {(user.role?.scopes.length ?? 0) > 0 && (
                    <Link to={`/admin`}>
                        <div className="px-3 py-2 border-b-2 text-blue-600 border-slate-400 hover:bg-slate-200">
                            Admin Tools
                        </div>
                    </Link>
                )}
                <Link to={`/logout`}>
                    <div className="px-3 py-2 text-red-500 hover:bg-slate-200">
                        Logout
                    </div>
                </Link>
            </div>
        </div>
    )
}

export const SignInLink = () => <Link className="hover:underline" to="/login">Sign In</Link>