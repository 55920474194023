import { ResultOf } from "@graphql-typed-document-node/core"
import { FragmentType, getFragmentData, graphql } from "generated"

export const ProjectCard_ProjectFragment = graphql(`
    fragment ProjectCard_ProjectFragment on Project {
        id
        name
        accessibleRoles {
            id
        }
        sessions {
            id
            name
        }
    }
`)

type Props = {
    project: FragmentType<typeof ProjectCard_ProjectFragment>
    baseComponent?: React.ComponentType<{
        className: string
        children?: React.ReactNode
        project: ResultOf<typeof ProjectCard_ProjectFragment>
    }>
}

const defaultBaseComponent = (props: any) => <div {...props} />

export default function ProjectCard({ baseComponent: BaseComponent = defaultBaseComponent, ...props }: Props) {
    const project = getFragmentData(ProjectCard_ProjectFragment, props.project)

    return (
        <BaseComponent project={project} className="p-4 rounded border border-gray-400 mb-4 flex flex-col sm:flex-row items-center">
            {project.name}
        </BaseComponent>
    )
}