import { ArrowDownOnSquareIcon } from "@heroicons/react/24/outline";
import { ButtonPrimaryOutlined } from "components/Button";
import ManageUserCard from "components/ManageUserCard";
import { graphql } from "generated";
import { AttendanceRecordsFilterInput, GetProjectSessionAttendanceRecordsQueryVariables } from "generated/graphql";
import useDocumentTitle from "lib/useDocumentTitle";
import { DateTime } from "luxon";
import { Suspense, useCallback, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useQuery } from "urql";

const GetProjectSessionDocument = graphql(`
    query GetProjectSession($projectId: ID!, $projectSessionId: ID!) {
        project(id: $projectId) {
            id
            name
            session(id: $projectSessionId) {
                id
                name
                weeks {
                    title
                    startTimestamp
                    endTimestamp
                }
            }
        }
    }
`)

const GetProjectSessionAttendanceRecordsDocument = graphql(`
    query GetProjectSessionAttendanceRecords($projectId: ID!, $projectSessionId: ID!, $filter: AttendanceRecordsFilterInput) {
        project(id: $projectId) {
            session(id: $projectSessionId) {
                attendanceRecords(filter: $filter) {
                    id
                    timestamp
                    type
                    user {
                        ...ManageUserCard_UserFragment
                        id
                        fullName
                        givenName
                        membership {
                            __typename
                        }
                        hasDemographics
                    }
                }
            }
        }
    }
`)

export default function AdminProjectAttendanceHistoryPage() {
    useDocumentTitle("Admin Project Attendance History | AIAA@UCF Members")
    const { projectId, projectSessionId } = useParams()
    const [filter, setFilter] = useState<AttendanceRecordsFilterInput>({
        userSearch: "",
        beforeTimestamp: 0,
        afterTimestamp: 0
    })
    const [timeSelection, setTimeSelection] = useState(-3)
    const [result] = useQuery({ query: GetProjectSessionDocument, variables: { projectId: projectId!, projectSessionId: projectSessionId! }, })

    const selectWeek = useCallback((idx: number) => {
        setTimeSelection(idx)
        if (result.data?.project?.session?.weeks != null && idx >= 0 && idx < result.data.project.session.weeks.length) {
            setFilter({
                userSearch: filter.userSearch,
                afterTimestamp: result.data.project.session.weeks[idx].startTimestamp,
                beforeTimestamp: result.data.project.session.weeks[idx].endTimestamp
            })
        }
        if (idx === -1) {
            setFilter({
                userSearch: filter.userSearch,
                afterTimestamp: 0,
                beforeTimestamp: Number.MAX_SAFE_INTEGER
            })
        }
    }, [filter, result])

    useEffect(() => {
        if (timeSelection === -3 && result?.data?.project?.session?.weeks != null) {
            selectWeek(result.data.project.session.weeks.length - 1)
        }
    }, [result.data?.project?.session?.weeks, selectWeek, timeSelection])

    if (result.data?.project?.session == null) {
        throw new Response("Not Found", { status: 404 })
    }

    return (
        <div>
            <div className="mb-4">
                <select value={timeSelection} onChange={e => selectWeek(parseInt(e.target.value))} className="bg-white border text-gray-700 rounded shadow focus:outline-none focus:shadow-outline block p-2.5">
                    <option value={-1}>All Time</option>
                    {result.data.project.session.weeks?.map((week, idx) => (
                        <option key={idx} value={idx}>{week.title}</option>
                    ))}
                    <option value={-2}>Custom range</option>
                </select>
            </div>
            {timeSelection === -2 && (
                <div className="mb-4">
                    <input
                        className="shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        type="date"
                        value={new Date(filter.afterTimestamp * 1000).toLocaleDateString('en-CA')}
                        onChange={e => {
                            const utcDate = new Date(e.target.value)
                            const date = new Date(utcDate.getUTCFullYear(), utcDate.getUTCMonth(), utcDate.getUTCDate())
                            setFilter({
                                userSearch: filter.userSearch,
                                beforeTimestamp: filter.beforeTimestamp,
                                afterTimestamp: Math.floor(date.getTime() / 1000)
                            })
                        }}
                    />
                    &nbsp;
                    to
                    &nbsp;
                    <input
                        className="shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        type="date"
                        value={new Date(filter.beforeTimestamp * 1000).toLocaleDateString('en-CA')}
                        onChange={e => {
                            const utcDate = new Date(e.target.value)
                            const date = new Date(utcDate.getUTCFullYear(), utcDate.getUTCMonth(), utcDate.getUTCDate())
                            setFilter({
                                userSearch: filter.userSearch,
                                afterTimestamp: filter.afterTimestamp,
                                beforeTimestamp: Math.floor(date.getTime() / 1000) + (24 * 60 * 60 - 1)
                            })
                        }}
                    />
                </div>
            )}
            <div className="mb-4">
                <input
                    className="shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    type="text"
                    value={filter.userSearch}
                    placeholder="Search Users"
                    onChange={(e) => setFilter({
                        afterTimestamp: filter.afterTimestamp,
                        beforeTimestamp: filter.beforeTimestamp,
                        userSearch: e.target.value
                    })}
                />
            </div>
            <Suspense>
                <AttendanceResultsList queryVars={{ projectId: projectId!, projectSessionId: projectSessionId!, filter: filter }} />
            </Suspense>
        </div>
    )
}

function AttendanceResultsList(props: { queryVars: GetProjectSessionAttendanceRecordsQueryVariables }) {
    const [result] = useQuery({ query: GetProjectSessionAttendanceRecordsDocument, variables: props.queryVars })

    if (result.data?.project?.session?.attendanceRecords == null) {
        throw new Response("Not Found", { status: 404 })
    }

    const downloadRecords = () => {
        function arrayToCsv(data: string[][]) {
            return data.map(row =>
                row
                    .map(String)  // convert every value to String
                    .map(v => v.replaceAll('"', '""'))  // escape double quotes
                    .map(v => `"${v}"`)  // quote it
                    .join(',')  // comma-separated
            ).join('\r\n');  // rows starting on new lines
        }
        const data = [
            ["Timestamp", "Scan Method", "User ID", "Full Name", "Given Name", "Has Membership", "Has Demographics"],
            ...result!.data!.project!.session!.attendanceRecords!.map(record => (
                [
                    new Date(record.timestamp * 1000).toLocaleString(), 
                    record.type.toString(), 
                    record.user?.id ?? "", 
                    record.user?.fullName ?? "", 
                    record.user?.givenName ?? "", 
                    (record.user?.membership != null).toString(), 
                    record.user?.hasDemographics?.toString() ?? ""
                ]
            ))
        ]

        const blob = new Blob([arrayToCsv(data)], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);

        const pom = document.createElement('a');
        pom.href = url;
        pom.setAttribute('download', "Attendance_Records.csv");
        pom.click();
    }

    return (
        <div>
            <div className="my-4">
                <span className="font-bold mr-6">{result.data.project.session.attendanceRecords.length} records</span>
                <ButtonPrimaryOutlined onClick={downloadRecords}>Download Records <ArrowDownOnSquareIcon className="h-5 w-5 inline" /></ButtonPrimaryOutlined>
            </div>
            <div>
                {result.data.project.session.attendanceRecords?.map(ar => ar.user != null && (
                    <ManageUserCard user={ar.user} extra={DateTime.fromSeconds(ar.timestamp).toLocaleString(DateTime.DATETIME_MED_WITH_SECONDS) + " by " + ar.type} baseComponent={LinkBaseComponent} />
                ))}
            </div>
        </div>
    )
}

const LinkBaseComponent = (props: {
    className: string
    children?: React.ReactNode
    userId: string
}) => <Link to={`/admin/users/${props.userId}`} {...props} />