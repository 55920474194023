import { FragmentType, getFragmentData, graphql } from "generated";
import { NavLink } from "react-router-dom";

export const AdminNavigationSidebar_UserFragment = graphql(`
    fragment AdminNavigationSidebar_UserFragment on User {
        id
        role {
            id
            scopes
        }
    }
`)

type Props = {
    user: FragmentType<typeof AdminNavigationSidebar_UserFragment>
}

export default function AdminNavigationSidebar(props: Props) {
    const user = getFragmentData(AdminNavigationSidebar_UserFragment, props.user)

    const classes = "flex items-center py-2 px-4 border border-b-0 border-gray-400 first:rounded-t last:rounded-b last:border-b hover:bg-gray-200 [&.active]:bg-aiaalightblue [&.active]:text-white"

    return (
        <div className="flex flex-col items-stretch md:mx-4">
            {user.role?.scopes?.includes("view-users") && (
                <NavLink className={classes} to="/admin/users">User Management</NavLink>
            )}
            {user.role?.scopes?.includes("view-projects") && (
                <NavLink className={classes} to="/admin/projects">Projects</NavLink>
            )}
            {/**
             *  <NavLink className={classes} to="/admin/roles">Roles</NavLink>
            <NavLink className={classes} to="/admin/projects">Projects</NavLink>
            <NavLink className={classes} to="/admin/attendance">Attendance</NavLink>
            <NavLink className={classes} to="/admin/lists">Lists</NavLink>
            <NavLink className={classes} to="/admin/acps">Access Control</NavLink> */}
        </div>
    )
}