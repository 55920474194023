import { graphql } from "generated"
import { Suspense, useState } from "react"
import { useQuery } from "urql"
import ManageUserCard from "./ManageUserCard"
import { Link } from "react-router-dom"

const SearchAllUsersDocument = graphql(`
    query SearchAllUsers($search: String!) {
        searchAllUsers(search: $search) {
            ...ManageUserCard_UserFragment
        }
    }
`)

export default function SearchAllUsers() {
    const [search, setSearch] = useState("")

    return (
        <div>
            <div>
                <h2 className="mb-2 text-xl">User Management</h2>
                <input
                    className="shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    type="text"
                    value={search}
                    placeholder="Search Users"
                    onChange={(e) => setSearch(e.target.value)}
                />
            </div>

            <div className="mt-6">
                <Suspense>
                    <AllUsersSearchList search={search} />
                </Suspense>
            </div>

        </div>
    )
}

type AllUsersSearchListProps = { search: string }

const LinkBaseComponent = (props: {
    className: string
    children?: React.ReactNode
    userId: string
}) => <Link to={`/admin/users/${props.userId}`} {...props} />

function AllUsersSearchList({ search }: AllUsersSearchListProps) {
    const [{ data }] = useQuery({ query: SearchAllUsersDocument, variables: { search } })

    return (
        <div>
            <ul>
                {data?.searchAllUsers?.map(u => (
                    <ManageUserCard user={u} baseComponent={LinkBaseComponent} />
                ))}
            </ul>

            {(data?.searchAllUsers?.length ?? 0) === 0 && (
                <p>No users found</p>
            )}
        </div>
    )
}