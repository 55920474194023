import { graphql } from "generated"
import { Navigate, useLocation, useSearchParams } from "react-router-dom"
import { useQuery } from "urql"
import { useState, useEffect } from "react"
import Cookies from "js-cookie"
import { constructLoginRedirectPath } from "lib/provideUrql"
import { AnimatePresence } from "framer-motion"
import useDocumentTitle from "lib/useDocumentTitle"
import OnboardingIntro from "components/onboarding/Intro"
import OnboardingEmails from "components/onboarding/Emails"
import OnboardingMembership from "components/onboarding/Membership"
import OnboardingFinish from "components/onboarding/Finish"
import OnboardingProfile from "components/onboarding/Profile"

const GetMeDocument = graphql(`
    query OnboardingPageBody_GetMe {
        me {
            id
            preferences {
                onboarded
            }
            ...OnboardingProfile_UserFragment
            ...OnboardingEmails_UserFragment
            ...OnboardingMembership_UserFragment
        }
    }
`)

export default function OnboardingPage() {
    useDocumentTitle("Onboarding | AIAA@UCF Members")

    const { pathname } = useLocation()
    const [searchParams] = useSearchParams()
    const [{ data }] = useQuery({ query: GetMeDocument })
    const [page, setPage] = useState<"intro" | "profile" | "emails" | "membership" | "finish">(searchParams.get("from") as any || "intro")
    useEffect(() => {
        if (searchParams.get("redirect-to") != null) {
            Cookies.set("onboarding-redirect-to", searchParams.get("redirect-to")!, {
                expires: new Date(Date.now() + (60 * 60 * 1000)),
                sameSite: "Lax"
            })
        }
    }, [searchParams])

    if (data?.me == null) {
        return <Navigate to={constructLoginRedirectPath(pathname)} />
    }
    if (data.me.preferences.onboarded) {
        return <Navigate to={"/account"} />
    }

    return (
        <div className="bg-blue-900 w-screen h-screen flex justify-center items-center text-white overflow-hidden">
            <AnimatePresence mode="wait">
                {page === "intro" && <OnboardingIntro key="intro" onNext={() => setPage("profile")} />}
                {page === "profile" && <OnboardingProfile key="profile" onNext={() => setPage("emails")} user={data.me} />}
                {page === "emails" && <OnboardingEmails key="emails" onNext={() => setPage("membership")} user={data.me} />}
                {page === "membership" && <OnboardingMembership key="membership" onNext={() => setPage("finish")} user={data.me} />}
                {page === "finish" && <OnboardingFinish key="finish" />}
            </AnimatePresence>
        </div>
    )
}