import Topbar from 'components/Topbar';
import { Outlet } from 'react-router-dom';

export default function WithTopbarLayout() {
    return (
        <div>
            <Topbar />
            <div>
                <Outlet />
            </div>
        </div>
    )
}