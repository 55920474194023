import { graphql } from "generated";
import { constructLoginRedirectPath, urqlClient } from "lib/provideUrql";
import { LoaderFunction, redirect } from "react-router-dom";

const EcwidSsoHandler_GetMe = graphql(`
    query EcwidSsoHandler_GetMe {
        me {
            id
            fullName
            membership {
                purchaseTimestamp
            }
        }
    }
`)
export const EcwidSsoHandler: LoaderFunction = async () => {
    const r = await urqlClient.query(EcwidSsoHandler_GetMe, {})

    if (r.data?.me?.__typename != null) {
        window.location.assign(`https://wix.ecwid.com/?userId=${r.data?.me.id}&fullName=${encodeURIComponent(r.data?.me.fullName)}&hasMembership=${r.data?.me.membership?.purchaseTimestamp != null}`)
        return null
    }

    return redirect(constructLoginRedirectPath("/ecwidsso"))
}