import { LinkButtonPrimary } from "components/Button"
import MembershipCard from "components/MembershipCard"
import { useLoader } from "components/PageLoading"
import { graphql } from "generated"
import { constructLoginRedirectPath } from "lib/provideUrql"
import useDocumentTitle from "lib/useDocumentTitle"
import { Link, Navigate, useLocation } from "react-router-dom"
import { toast } from "react-toastify"
import { useMutation, useQuery } from "urql"

const GetMeDocument = graphql(`
    query AccountMembershipPage_GetMe {
        me {
            ...MembershipCard_UserFragment
            membership {
                ...MembershipCard_MembershipFragment
            }
        }
    }
`)

const AccountMembershipPageBody_CreateMembershipPurchaseSession = graphql(`
    mutation AccountMembershipPageBody_CreateMembershipPurchaseSession {
        createMembershipPurchaseSession {
            id
            paymentLink
        }
    }
`)

export default function AccountMembershipPage() {
    useDocumentTitle("Membership | AIAA@UCF Members")
    const { pathname } = useLocation()
    const loader = useLoader()
    const [{ data }] = useQuery({ query: GetMeDocument })
    const [, createPurchaseSession] = useMutation(AccountMembershipPageBody_CreateMembershipPurchaseSession)

    if (data?.me == null) {
        return <Navigate to={constructLoginRedirectPath(pathname)} />
    }

    const user = data.me

    const startPurchase = async () => {
        try {
            loader.start()
            const r = await createPurchaseSession({})
            if ((r.error?.graphQLErrors.length ?? 0) > 0) {
                loader.stop()
                return toast.error(r.error!.graphQLErrors[0].message)
            }
            const link = r.data?.createMembershipPurchaseSession?.paymentLink
            if (link == null) {
                loader.stop()
                return toast.error("Unknown error when starting payment")
            }
            window.location.assign(link)
        } finally {}
    }

    return (
        <div>
            {user.membership != null && (
                <div>
                    <h2 className="mb-2 text-2xl">You have a valid membership!</h2>
                    <p className="my-2">With your AIAA@UCF Membership, you can participate in <b>all</b> of our projects, competitions, networking opportunities, and more!</p>
                    <p className="my-2"><b>IMPORTANT: AIAA@UCF Membership is different from AIAA National student membership.</b></p>
                    <p className="my-4">Here are the details of your membership:</p>
                    <MembershipCard membership={user.membership} user={user} />
                </div>
            )}

            {user.membership == null && (
                <div>
                    <h2 className="mb-2 text-2xl">You do not have a valid membership</h2>
                    <p className="my-2">With an AIAA@UCF Membership you get</p>
                    <ul className="list-disc list-inside ml-4">
                        <li>Access to <b>all</b> Design & Research Projects</li>
                        <li>Exclusive Tour Opportunities</li>
                        <li>Networking Opportunities</li>
                        <li>Leadership Opportunities</li>
                        <li>Resume Building</li>
                    </ul>
                    <p className="my-2">All for just $30!</p>
                    <p className="my-2">Get your membership today!</p>
                    <p className="my-2"><b>IMPORTANT: AIAA@UCF Membership is different from AIAA National student membership.</b></p>
                    <LinkButtonPrimary onClick={(e) => { e.preventDefault(); startPurchase() }} to={"/purchasemembership"}>Purchase Membership</LinkButtonPrimary>
                    <div className="italic text-xs mt-6">Purchased a membership from the aiaaucf.com store? <Link to="/account/membership/legacy" className="hover:underline text-blue-500">Link your order</Link></div>
                </div>
            )}
        </div>
    )
}