import { FragmentType, getFragmentData, graphql } from "generated"
import ProfilePicture from "./ProfilePicture"

export const ManageUserCard_UserFragment = graphql(`
    fragment ManageUserCard_UserFragment on User {
        id
        fullName
        membership {
            __typename
        }
        hasDemographics

        ...ProfilePicture_UserFragment
    }
`)

type Props = {
    user: FragmentType<typeof ManageUserCard_UserFragment>
    baseComponent?: React.ComponentType<{
        className: string
        children: React.ReactNode | undefined
        userId: string
    }>
    extra?: string
}

const defaultBaseComponent = (props: any) => <div {...props} />

export default function ManageUserCard({ baseComponent: BaseComponent = defaultBaseComponent, ...props }: Props) {
    const user = getFragmentData(ManageUserCard_UserFragment, props.user)

    return (
        <BaseComponent userId={user.id} className="p-4 rounded border border-gray-400 mb-4 flex flex-col sm:flex-row items-center">
            <ProfilePicture user={user} />
            <div className="flex flex-col items-center sm:items-start ml-4 pt-4">
                {props.extra && <span>{props.extra}</span>}
                <span className="text-xl">{user.fullName}</span>
                {user.membership != null && (
                    <div className="rounded-lg bg-green-600 py-1.5 px-3 text-xs font-bold uppercase text-white">
                        Has Active Membership
                    </div>
                )}
                {user.membership == null && (
                    <div className="rounded-lg bg-red-600 py-1.5 px-3 text-xs font-bold uppercase text-white">
                        No Active Membership
                    </div>
                )}
                {user.hasDemographics === true && (
                    <div className="rounded-lg bg-green-600 my-1 py-1.5 px-3 text-xs font-bold uppercase text-white">
                        Has Demographics
                    </div>
                )}
                {user.hasDemographics === false && (
                    <div className="rounded-lg bg-red-600 my-1 py-1.5 px-3 text-xs font-bold uppercase text-white">
                        Needs Demographics
                    </div>
                )}
            </div>
        </BaseComponent>
    )
}