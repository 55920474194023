import { useRouteError } from "react-router-dom";
import { LinkButtonPrimary } from "./components/Button"
import Topbar from "./components/Topbar"

export default function ErrorPage() {
    const error = useRouteError()
    console.error(error)


    return (
        <div>
            <Topbar />
            <div className='w-screen flex flex-col items-center mt-8'>
                <h2 className='text-6xl font-bold my-6'>Oh No!</h2>
                <img src="/X-29-turn.gif" alt='X-29 Turning Gif' />
                <p className='my-2'>It looks like you turned into the wrong page</p>
                <LinkButtonPrimary to="/">Return Home</LinkButtonPrimary>
            </div>
        </div>
    )
}