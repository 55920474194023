import AdminProjectAttendanceNavigationTopbar from "components/AdminProjectAttendanceNavigationTopbar";
import { Outlet, useParams } from "react-router-dom";

export default function AdminProjectAttendanceLayout() {
    const { projectId, projectSessionId } = useParams()

    return (
        <div>
            <div>
                <AdminProjectAttendanceNavigationTopbar projectId={projectId!} projectSessionId={projectSessionId!} />
            </div>
            <div className="px-4 pt-4">
                <Outlet />
            </div>
        </div>
    )
}