import { graphql } from "generated"
import { useQuery } from "urql"
import { Navigate, useLocation } from "react-router-dom"

const ForceOnboarding_GetMe = graphql(`
    query ForceOnboarding_GetMe {
        me {
            preferences {
                onboarded
            }
        }
    }
`)


export default function ForceOnboarding() {
    const { pathname } = useLocation()
    const [{ data }] = useQuery({ query: ForceOnboarding_GetMe, pause: pathname.startsWith("/onboarding") })

    if (data?.me?.preferences?.onboarded === false) {
        if (!(pathname.startsWith("/onboarding") || pathname.startsWith("/paymentcallback") || pathname.startsWith("/purchasemembership"))) {
            return <Navigate to={constructOnboardingRedirectPath(pathname)} />
        }
    }

    return null
}

function constructOnboardingRedirectPath(page?: string): string {
    const p = new URLSearchParams()
    if (page != null) p.set("redirect-to", page)
    const params = p.toString()
    return `/onboarding${params.length > 0 ? "?" : ""}${params}`
  }