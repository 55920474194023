import { FragmentType, getFragmentData, graphql } from "generated";
import { DateTime } from "luxon";

export const MembershipCard_UserFragment = graphql(`
    fragment MembershipCard_UserFragment on User {
        id
        fullName
    }
`)

export const MembershipCard_MembershipFragment = graphql(`
    fragment MembershipCard_MembershipFragment on Membership {
        purchaseTimestamp
        expiresAtTimestamp
        renewableAtTimestamp
        flags {
            name
            color
            visible
        }
    }
`)

type Props = {
    user: FragmentType<typeof MembershipCard_UserFragment>,
    membership: FragmentType<typeof MembershipCard_MembershipFragment>
}

export default function MembershipCard(props: Props) {
    const user = getFragmentData(MembershipCard_UserFragment, props.user)
    const membership = getFragmentData(MembershipCard_MembershipFragment, props.membership)

    return (
        <div className="p-6 border border-gray-400 rounded inline-block">
            <h1 className="text-3xl font-bold">{user.fullName}</h1>
            <div>Purchased: {DateTime.fromSeconds(membership.purchaseTimestamp).toLocaleString(DateTime.DATE_FULL)}</div>
            <div>Expires: {DateTime.fromSeconds(membership.expiresAtTimestamp).toLocaleString(DateTime.DATE_FULL)}</div>
            {membership.renewableAtTimestamp > Date.now() / 1000 && (
                <div className="italic ">Not yet renewable</div>
            )}
            <div className="flex flex-wrap">
                {membership.flags?.filter(f => f.visible)?.map(f => (
                    <div key={f.name} className="rounded text-white w-auto text-xs p-1" style={{ backgroundColor: `#${f.color}` }}>{f.name}</div>
                ))}
            </div>
        </div>
    )
}