import { ButtonPrimary } from "components/Button"
import { useLoader } from "components/PageLoading"
import { graphql } from "generated"
import { constructLoginRedirectPath } from "lib/provideUrql"
import useDocumentTitle from "lib/useDocumentTitle"
import { Navigate, useLocation } from "react-router-dom"
import { toast } from "react-toastify"
import { useClient, useQuery } from "urql"

const GetMeDocument = graphql(`
    query AccountDemographicsPage_GetMe {
        me {
            hasDemographics
        }
    }
`)

const GetMyDemographicsSurveyUrlDocument = graphql(`
    query AccountDemographicsPage_GetMyDemographicsSurveyUrl {
        myDemographicsSurveyUrl
    }
`)

export default function AccountDemographicsPage() {
    useDocumentTitle("Demographics | AIAA@UCF Members")
    const { pathname } = useLocation()
    const loader = useLoader()
    const client = useClient()
    const [{ data }] = useQuery({ query: GetMeDocument })

    if (data?.me == null) {
        return <Navigate to={constructLoginRedirectPath(pathname)} />
    }

    const user = data.me

    const openDemographicsForm = async () => {
        try {
            loader.start()
            const r = await client.query(GetMyDemographicsSurveyUrlDocument, {})
            if ((r.error?.graphQLErrors.length ?? 0) > 0) {
                loader.stop()
                return toast.error(r.error!.graphQLErrors[0].message)
            }
            const link = r.data?.myDemographicsSurveyUrl
            if (link == null) {
                loader.stop()
                return toast.error("Unknown error when opening survey")
            }
            window.location.assign(link)
        } finally {}
    }

    return (
        <div>
            {user.hasDemographics === false && (
                <div>
                    <h2 className="mb-2 text-2xl">Submit Demographics Info</h2>
                    <p className="my-2">In order to qualify for grants, AIAA@UCF needs to collect some demographic information about our members. </p>
                    <p className="my-2">Every semester, we ask that all our members submit a demographics questionnare. Your participation helps us continue to secure funding for our projects and events.</p>
                    <p className="my-2">If you have any questions, please reach out to <a className="hover:underline text-blue-500" href="mailto:treasurer@aiaaucf.com" target="_blank" rel="noreferrer">treasurer@aiaaucf.com</a></p>
                    <ButtonPrimary onClick={openDemographicsForm}>Submit Demographics</ButtonPrimary>
                </div>
            )}
            {user.hasDemographics === true && (
                <div>
                    <h2 className="mb-2 text-2xl">Demographics Info Submitted!</h2>
                    <p className="my-2">AIAA@UCF thanks you for submitting your demographics, which will help us secure funding in future.</p>
                    <p className="my-2">Your demographic information is held on file for this semester only. You will need to resubmit your demographcs info at the start of each semester in which you intend to participate in AIAA@UCF</p>
                    <p className="my-2">If you have any questions, please reach out to <a className="hover:underline text-blue-500" href="mailto:treasurer@aiaaucf.com" target="_blank" rel="noreferrer">treasurer@aiaaucf.com</a></p>
                </div>
            )}
            {user.hasDemographics == null && (
                <div>
                    <p className="my-2">Demographics submission not available at this time.</p>
                </div>
            )}
        </div>
    )
}