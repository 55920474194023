import { FragmentType, getFragmentData, graphql } from "generated"

export const ProfilePicture_UserFragment = graphql(`
    fragment ProfilePicture_UserFragment on User {
        id
        profilePicture
    }
`)

type Props = {
    user: FragmentType<typeof ProfilePicture_UserFragment>
    size?: number
}

export default function ProfilePicture(props: Props) {
    const user = getFragmentData(ProfilePicture_UserFragment, props.user)
    return (
        <div>
            <img
                alt="Profile"
                src={user.profilePicture}
                width={props.size ?? 128}
                height={props.size ?? 128}
                className="rounded-full border-black border-2" />
        </div>
    )
}