import ProfilePicture from "components/ProfilePicture"
import { graphql } from "generated"
import { constructLoginRedirectPath } from "lib/provideUrql"
import useDocumentTitle from "lib/useDocumentTitle"
import QRCode from "react-qr-code"
import { Navigate, useLocation } from "react-router-dom"
import { useQuery } from "urql"

const MyQrPageBody_GetMyJwtQr = graphql(`
    query MyQrPageBody_GetMyJwtQr {
        myJwtQr
        me {
            ...ProfilePicture_UserFragment
            fullName
        }
    }
`)

export default function MyQrPage() {
    useDocumentTitle("MyQr | AIAA@UCF Members")

    const { pathname } = useLocation()
    const [{ data, error }] = useQuery({ query: MyQrPageBody_GetMyJwtQr })

    if (error?.graphQLErrors?.some(e => e.extensions.code === "UNAUTHENTICATED")) {
        return <Navigate to={constructLoginRedirectPath(pathname)} />
    }

    return (
        <div className="flex flex-col justify-center items-center w-full mt-6">
            <h1 className="m-6 w-auto text-2xl text-center">
                Show this QR code during Project Attendance or to gain access to member events
            </h1>
            <div className="flex flex-col justify-center items-center p-2 aspect-square w-[min(70vw,_70vh)] max-w-sm h-auto bg-aiaablue rounded-lg">
                <div className="flex-col flex justify-center items-center p-2 w-full h-auto aspect-square bg-white rounded-lg">
                    {data?.myJwtQr != null && <QRCode value={data.myJwtQr} className="w-full h-auto aspect-square" />}
                </div>
            </div>
            <div className="my-6 flex justify-center items-center">
                {data?.me != null && <ProfilePicture user={data.me} size={64} />}
                <div className="mx-6 text-lg">{data?.me?.fullName}</div>
            </div>
        </div>
    )
}