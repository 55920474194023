import { NavLink } from "react-router-dom"

type Props = {
    projectId: string
    projectSessionId: string
}

export default function AdminProjectAttendanceNavigationTopbar(props: Props) {
    const classes = "inline-block px-4 py-3 [&.active]:text-white [&.active]:bg-aiaablue hover:text-gray-900 hover:bg-gray-100 rounded-lg"

    return (


        <ul className="flex flex-wrap text-sm font-medium text-center text-gray-500 dark:text-gray-400">
            <li className="me-2">
                <NavLink to={`/admin/projects/${props.projectId}/${props.projectSessionId}/attendance`} className={classes} end>Take Attendance</NavLink>
            </li>
            <li className="me-2">
                <NavLink to={`/admin/projects/${props.projectId}/${props.projectSessionId}/attendance/history`} className={classes} end>Attendance History</NavLink>
            </li>
        </ul>

    )
}