import SimpleModal from "components/SimpleModal"
import UploadProfilePicture from "components/UploadProfilePicture"
import { graphql } from "generated"
import { constructLoginRedirectPath } from "lib/provideUrql"
import { useState } from "react"
import { Navigate, useLocation } from "react-router-dom"
import { useQuery } from "urql"
import { toast } from 'react-toastify'
import ClickableProfilePicture from "components/ClickableProfilePicture"
import EditAccountProfile from "components/EditAccountProfile"
import useDocumentTitle from "lib/useDocumentTitle"


const GetMeDocument = graphql(`
    query AccountProfilePage_GetMe {
        me {
            ...EditAccountProfile_UserFragment
            ...ClickableProfilePicture_UserFragment
        }
    }
`)

export default function AccountProfilePage() {
    useDocumentTitle("Account Profile | AIAA@UCF Members")
    const { pathname } = useLocation()
    const [uploadPfpOpen, setUploadPfpOpen] = useState(false)
    const [{ data }] = useQuery({ query: GetMeDocument })

    if (data?.me == null) {
        return <Navigate to={constructLoginRedirectPath(pathname)} />
    }

    return (
        <div className="ml-6">
            <SimpleModal isOpen={uploadPfpOpen} onClose={() => setUploadPfpOpen(false)} closeText="Cancel">
                <UploadProfilePicture
                    onSave={() => { setUploadPfpOpen(false); toast.success("Profile picture saved") }}
                    onError={(e) => toast.error(e)}
                />
            </SimpleModal>
            <div className="mb-6">
                <ClickableProfilePicture user={data.me} onClick={() => setUploadPfpOpen(true)} />
            </div>
            <EditAccountProfile
                user={data.me}
                onSave={(s) => s ? toast.success("Account profile saved") : toast.warn("Nothing was changed")}
                onError={(e) => toast.error(e)}
            />
        </div>
    )
}