import { motion } from "framer-motion";
import { ButtonWhite } from "components/Button";
import { ArrowRightIcon } from "@heroicons/react/24/solid";
import { graphql } from "generated";
import { useMutation } from "urql";
import { useLoader } from "components/PageLoading";
import Cookies from "js-cookie";

const OnboardingFinish_CreateMembershipPurchaseSession = graphql(`
    mutation OnboardingFinish_CreateMembershipPurchaseSession {
        finishMyOnboarding {
            id
        }
    }
`)

type Props = {}

export default function OnboardingFinish(props: Props) {
    const loading = useLoader()
    const [, finishOnboarding] = useMutation(OnboardingFinish_CreateMembershipPurchaseSession)

    const url = new URL(window.location.href)
    url.search = ""
    url.pathname = Cookies.get("onboarding-redirect-to") ?? "/"

    const finish = async () => {
        try {
            loading.start()
            await finishOnboarding({})
        } finally {
            window.location.assign(url.toString())
        }
    }

    return (
        <motion.div className="flex flex-col justify-center items-center px-4" initial={{ opacity: 0, translateX: 100 }} animate={{ opacity: 100, translateX: 0 }} exit={{ translateX: -100, opacity: 0 }}>
            <img alt="AIAA UCF Logo" src="/aiaa-ucf-logo-white.png" />
            <p className="text-2xl text-center my-6 font-bold">Account Setup Complete</p>
            <p className="my-6 px-2">Your AIAA UCF Account is ready to go. We hope to see you soon!</p>
            <div className="my-6">
                <ButtonWhite onClick={finish}>
                    Let's Go
                    <ArrowRightIcon className="h-6 w-6 inline" />
                </ButtonWhite>
            </div>
        </motion.div>
    )
}