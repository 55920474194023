import { graphql } from "generated";
import { urqlClient } from "lib/provideUrql";
import { LoaderFunction, redirect } from "react-router-dom";

const ConfirmEmailHandler_ConfirmEmail = graphql(`
    mutation ConfirmEmailHandler_ConfirmEmail($code: String!) {
        confirmEmail(code: $code)
    }
`)

const GetMeDocument = graphql(`
    query ConfirmEmailHandler_GetMe {
        me {
            preferences {
                onboarded
            }
        }
    }
`)

export const ConfirmEmailHandler: LoaderFunction = async ({ params }) => {
    const confirmEmailResponse = await urqlClient.mutation(ConfirmEmailHandler_ConfirmEmail, { code: params.code! })

    if (confirmEmailResponse.data?.confirmEmail === false) {
        return redirect("/confirmemailfailed")
    }

    const userResponse = await urqlClient.query(GetMeDocument, {})
    console.log(userResponse)
    if (userResponse.data?.me?.preferences?.onboarded === false) {
        return redirect("/onboarding?from=emails")
    }

    return redirect("/account/emails")
}