
export default function MembersPage() {
    return (
        <div className="flex justify-center mt-6">
            <div className="container flex-col flex justify-center items-center p-6 bg-yellow-300 border-4 border-yellow-600 rounded-lg text-yellow-900">
                <h1 className="text-3xl">This page is under construction</h1>
                <p className="mt-8">We're still working on this feature. In the future, you will be able to search AIAA@UCF members, see who is participating in our projects, and customize your AIAA@UCF profile. Stay tuned as we roll out these features this semester!</p>
            </div>
        </div>
    )
}