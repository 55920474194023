import { graphql } from "generated"
import { constructLoginRedirectPath } from "lib/provideUrql"
import { Navigate, useLocation } from "react-router-dom"
import { useQuery } from "urql"
import { toast } from 'react-toastify'
import useDocumentTitle from "lib/useDocumentTitle"
import SearchAllUsers from "components/SearchAllUsers"


const GetMeDocument = graphql(`
    query AdminUsersPage_GetMe {
        me {
            role {
                id
                scopes
            }
        }
    }
`)

export default function AdminUsersPage() {
    useDocumentTitle("Admin User Management | AIAA@UCF Members")
    const { pathname } = useLocation()
    const [{ data }] = useQuery({ query: GetMeDocument })

    if (data?.me == null) {
        return <Navigate to={constructLoginRedirectPath(pathname)} />
    }

    if (!data.me.role?.scopes?.includes("view-users")) {
        toast.error("You don't have permission to access this page", {
            toastId: "nopagepermission"
        })
        return <Navigate to="/" />
    }

    return (
        <div className="ml-6">
            <SearchAllUsers />
        </div>
    )
}