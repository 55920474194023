import AdminNavigationSidebar from "components/AdminNavigationSidebar";
import { graphql } from "generated";
import { constructLoginRedirectPath } from "lib/provideUrql";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useQuery } from "urql";

const GetMeDocument = graphql(`
    query AdminLayout_GetMe {
        me {
            ...AdminNavigationSidebar_UserFragment
        }
    }
`)

export default function AdminLayout() {
    const { pathname } = useLocation()
    const [{ data }] = useQuery({ query: GetMeDocument })

    if (data?.me == null) {
        return <Navigate to={constructLoginRedirectPath(pathname)} />
    }

    return (
        <div className="flex justify-center">
            <div className="container md:mt-12 p-6">
                <div className="md:grid md:grid-rows-1 md:grid-cols-6">
                    <div className="md:col-span-2 lg:col-span-1 mb-6 md:mb-0">
                        <AdminNavigationSidebar user={data.me} />
                    </div>

                    <div className="md:col-span-4 lg:col-span-5">
                        <Outlet />
                    </div>
                </div>
            </div>
        </div>
    )
}