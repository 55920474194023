import { graphql } from "generated"
import { constructLoginRedirectPath } from "lib/provideUrql"
import { Link, Navigate, useLocation } from "react-router-dom"
import { useQuery } from "urql"
import { toast } from 'react-toastify'
import useDocumentTitle from "lib/useDocumentTitle"
import AllProjectsList from "components/AllProjectsList"


const GetMeAndProjectsDocument = graphql(`
    query AdminProjectsPage_GetMeAndProjects {
        me {
            role {
                id
                scopes
            }
        }
        projects {
            ...AllProjectsList_ProjectFragment
        }
    }
`)

export default function AdminProjectsPage() {
    useDocumentTitle("Admin Projects Management | AIAA@UCF Members")
    const { pathname } = useLocation()
    const [{ data }] = useQuery({ query: GetMeAndProjectsDocument })

    if (data?.me == null) {
        return <Navigate to={constructLoginRedirectPath(pathname)} />
    }

    if (!data.me.role?.scopes?.includes("view-projects")) {
        toast.error("You don't have permission to access this page", {
            toastId: "nopagepermission"
        })
        return <Navigate to="/" />
    }

    return (
        <div className="ml-6">
            <AllProjectsList projects={data.projects ?? []} baseComponent={LinkBaseComponent} />
        </div>
    )
}

const LinkBaseComponent: Parameters<typeof AllProjectsList>[0]["baseComponent"] = (props) =>
    <Link to={`/admin/projects/${props.project.id}/${props.project.sessions![0].id}/attendance`} {...props} /> //TODO This is temp pls fix