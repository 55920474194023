import { NavLink } from "react-router-dom"

type Props = {
    projectId: string
    projectSessionId: string
}

export default function AdminProjectNavigationTopbar(props: Props) {
    const classes = "inline-block p-4 rounded-t-lg hover:text-gray-600 hover:bg-gray-50  [&.active]:bg-gray-100 [&.active]:text-aiaablue"

    return (
        <ul className="flex flex-wrap text-center text-gray-500 border-b border-gray-200 dark:border-gray-700 dark:text-gray-400">
            <li className="me-2">
                <NavLink to={`/admin/projects/${props.projectId}/${props.projectSessionId}`} className={classes} end>Description</NavLink>
                <NavLink to={`/admin/projects/${props.projectId}/${props.projectSessionId}/attendance`} className={classes}>Attendance</NavLink>
                <NavLink to={`/admin/projects/${props.projectId}/${props.projectSessionId}/members`} className={classes}>Confirmed Members</NavLink>
            </li>
        </ul>
    )
}