import { PropsWithChildren, ReactNode, useEffect } from "react"
import Modal from 'react-modal';
import { ButtonPrimaryOutlined } from "./Button";

export default function SimpleModal(props: PropsWithChildren<{ isOpen: boolean, onClose: () => void, actions?: ReactNode, closeText?: string }>) {

    useEffect(() => {
        Modal.setAppElement("#root")
    }, [])

    return (
        <div>
            <Modal
                closeTimeoutMS={0}
                isOpen={props.isOpen}
                onRequestClose={() => props.onClose()}
                className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg"
                overlayClassName="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center"
            >
                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                    {props.children}
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6 [&>*]:mx-2">
                    {props.actions}
                    <ButtonPrimaryOutlined onClick={props.onClose}>{props.closeText ?? "Close"}</ButtonPrimaryOutlined>
                    
                </div>
            </Modal>
        </div>
    )
}
