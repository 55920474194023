import { ArrowRightIcon } from "@heroicons/react/20/solid";
import { ButtonWhite } from "components/Button";
import { motion } from "framer-motion";

type Props = {
    onNext(): void
}

export default function OnboardingIntro(props: Props) {
    return (
        <motion.div className="flex flex-col justify-center items-center px-4" initial={{ opacity: 0, translateX: 0, translateY: 100 }} animate={{ opacity: 100, translateX: 0, translateY: 0 }} exit={{ translateX: -100, translateY: 0, opacity: 0 }}>
            <img alt="AIAA@UCF Logo" src="/aiaa-ucf-logo-white.png" />
            <p className="text-2xl text-center my-6 font-bold">Welcome to your AIAA@UCF Account</p>
            <p className="my-6 px-2 w-full">This is your hub for everything in AIAA@UCF</p>
            <ul className="list-disc list-inside w-full pl-4">
                <li>Join projects</li>
                <li>Pay dues</li>
                <li>Find members</li>
                <li>See your history</li>
            </ul>
            <div className="w-full my-6">
                <ButtonWhite onClick={props.onNext}>
                    Get Started
                    <ArrowRightIcon className="h-6 w-6 inline" />
                </ButtonWhite>
            </div>
        </motion.div>
    )
}