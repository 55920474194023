import { motion } from "framer-motion";
import { ButtonPrimary, ButtonPrimaryOutlined, LinkButtonPrimary } from "components/Button";
import { useState } from "react";
import { toast } from "react-toastify";
import { useMutation } from "urql";
import { FragmentType, getFragmentData, graphql } from "generated";
import { useLoader } from "components/PageLoading";
import MembershipCard from "components/MembershipCard";
import LinkLegacyMembership from "components/LinkLegacyMembership";

export const OnboardingMembership_UserFragment = graphql(`
    fragment OnboardingMembership_UserFragment on User {
        id
        ...MembershipCard_UserFragment
        ...LinkLegacyMembership_UserFragment
        membership {
            ...MembershipCard_MembershipFragment
        }
    }
`)

const OnboardingMembership_CreateMembershipPurchaseSession = graphql(`
    mutation OnboardingMembership_CreateMembershipPurchaseSession {
        createMembershipPurchaseSession {
            id
            paymentLink
        }
    }
`)

type Props = {
    user: FragmentType<typeof OnboardingMembership_UserFragment>
    onNext(): void
}

export default function OnboardingMembership(props: Props) {
    const loader = useLoader()
    const user = getFragmentData(OnboardingMembership_UserFragment, props.user)
    const [page, setPage] = useState<"prompt" | "legacy">("prompt")
    const [, createPurchaseSession] = useMutation(OnboardingMembership_CreateMembershipPurchaseSession)


    const startPurchase = async () => {
        try {
            loader.start()
            const r = await createPurchaseSession({})
            if ((r.error?.graphQLErrors.length ?? 0) > 0) {
                loader.stop()
                return toast.error(r.error!.graphQLErrors[0].message)
            }
            const link = r.data?.createMembershipPurchaseSession?.paymentLink
            if (link == null) {
                loader.stop()
                return toast.error("Unknown error when starting payment")
            }
            window.location.assign(link)
        } finally {}
    }

    return (
        <motion.div className="flex flex-col justify-center items-center px-4 w-full" initial={{ opacity: 0, translateX: 100 }} animate={{ opacity: 100, translateX: 0 }} exit={{ translateX: -100, opacity: 0 }}>
            <p className="text-xl text-center my-6 font-bold">AIAA UCF Membership</p>
            <div className="bg-white text-black p-6 rounded-lg w-full max-w-3xl">
                {page === "prompt" && (
                    <>
                        {user.membership != null && (
                            <div>
                                <h2 className="mb-2 text-lg">Your membership has been linked!</h2>
                                <p className="my-4">Here are the details of your membership:</p>
                                <MembershipCard membership={user.membership} user={user} />
                                <div className="my-6">
                                    <ButtonPrimary onClick={props.onNext}>Continue</ButtonPrimary>
                                </div>
                            </div>
                        )}
                        {user.membership == null && (
                            <div>
                                <p className="my-2">With an AIAA UCF Membership you get</p>
                                <ul className="list-disc list-inside ml-4">
                                    <li>Access to <b>all</b> Design & Research Projects</li>
                                    <li>Exclusive Tour Opportunities</li>
                                    <li>Networking Opportunities</li>
                                    <li>Leadership Opportunities</li>
                                    <li>Resume Building</li>
                                </ul>
                                <p className="my-2">All for just $30!</p>
                                <p className="my-2">Get your membership today!</p>
                                <p className="my-2"><b>IMPORTANT: AIAA UCF Membership is different from AIAA National student membership.</b></p>
                                <div className="flex sm:block flex-col items-stretch">
                                    <LinkButtonPrimary className="sm:mr-6 mb-6" onClick={(e) => { e.preventDefault(); startPurchase() }} to={"/purchasemembership"}>Purchase Membership</LinkButtonPrimary>
                                    <ButtonPrimaryOutlined onClick={props.onNext} >Skip for now</ButtonPrimaryOutlined>
                                </div>
                                <div className="italic text-s mt-6">Purchased a membership from the aiaaucf.com store? <button onClick={() => setPage("legacy")} className="hover:underline text-blue-500">Link your order</button></div>
                            </div>
                        )}
                    </>
                )}

                {page === "legacy" && (
                    <div>
                        <p>Purchased a membership from the aiaaucf.com store? Link your purchase to your new AIAA UCF Account!</p>
                        <div className="my-8">
                            <LinkLegacyMembership user={user} successAction={<ButtonPrimary className="my-6" onClick={props.onNext}>Continue</ButtonPrimary>} />
                        </div>
                        <ButtonPrimaryOutlined onClick={() => setPage("prompt")}>Back</ButtonPrimaryOutlined>
                    </div>
                )}
            </div>
        </motion.div>
    )
}