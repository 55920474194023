import { Link } from "react-router-dom";

export function ButtonPrimary(props: React.ButtonHTMLAttributes<HTMLButtonElement>) {
    return <button {...props} className={["text-center font-bold py-2 px-4 rounded bg-aiaablue text-white hover:bg-aiaalightblue", props.className ?? ""].join(" ")} />
}

export function ButtonWhite(props: React.ButtonHTMLAttributes<HTMLButtonElement>) {
    return <button {...props} className={["text-center font-bold py-2 px-4 rounded bg-white text-black hover:bg-gray-300", props.className ?? ""].join(" ")} />
}

export function ButtonPrimaryOutlined(props: React.ButtonHTMLAttributes<HTMLButtonElement>) {
    return <button {...props} className={["text-center font-bold py-2 px-4 rounded border-2 border-aiaablue text-aiaablue hover:bg-aiaablue hover:text-white", props.className ?? ""].join(" ")} />
}

export function ButtonDanger(props: React.ButtonHTMLAttributes<HTMLButtonElement>) {
    return <button {...props} className={["text-center font-bold py-2 px-4 rounded bg-red-500 text-white hover:bg-red-800", props.className ?? ""].join(" ")} />
}

export function ButtonSuccess(props: React.ButtonHTMLAttributes<HTMLButtonElement>) {
    return <button {...props} className={["text-center font-bold py-2 px-4 rounded bg-green-500 text-white hover:bg-green-800", props.className ?? ""].join(" ")} />
}

export function ButtonDisabled(props: React.ButtonHTMLAttributes<HTMLButtonElement>) {
    return <button {...props} disabled className={["text-center font-bold py-2 px-4 rounded bg-gray-600 text-gray-400 hover:cursor-not-allowed", props.className ?? ""].join(" ")} />
}

export function LinkButtonPrimary(props: React.ComponentProps<typeof Link>) {
    return <Link {...props} className={["text-center font-bold py-2 px-4 rounded bg-aiaablue text-white hover:bg-aiaalightblue", props.className ?? ""].join(" ")} />
}

export function LinkButtonPrimaryOutlined(props: React.ComponentProps<typeof Link>) {
    return <Link {...props} className={["text-center font-bold py-2 px-4 rounded border-2 border-aiaablue text-aiaablue hover:bg-aiaablue hover:text-white", props.className ?? ""].join(" ")} />
}

export function LinkButtonWhite(props: React.ComponentProps<typeof Link>) {
    return <Link {...props} className={["text-center font-bold py-2 px-4 rounded bg-white text-black hover:bg-gray-300", props.className ?? ""].join(" ")} />
}