import { authExchange } from '@urql/exchange-auth';
import Cookies from 'js-cookie';
import { redirect } from 'react-router-dom';
import { Client, cacheExchange, fetchExchange } from 'urql';

export const urqlClient = new Client({
    url: '/api/graphql',
    exchanges: [
        cacheExchange,
        authExchange(async utils => {
            const token = Cookies.get("token")

            return {
                addAuthToOperation(operation) {
                    if (!token) return operation
                    return utils.appendHeaders(operation, {
                        Authorization: `User ${token}`,
                    })
                },

                didAuthError(error, _operation) {
                    return error.graphQLErrors.some(e => e.extensions?.code === "UNAUTHENTICATED")
                },

                async refreshAuth() {
                    //TODO Fix
                    redirect(constructLoginRedirectPath(window.location.pathname))
                }
            }
        }),
        fetchExchange
    ],
    suspense: true
})

export function constructLoginRedirectPath(page?: string): string { //TODO Allow query params
    const p = new URLSearchParams()
    if (page != null) p.set("redirect-to", page)
    const params = p.toString()
    return `/login${params.length > 0 ? "?" : ""}${params}`
}