import { ArrowDownOnSquareIcon, ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";
import { ButtonPrimary, LinkButtonPrimary } from "components/Button";
import { graphql } from "generated";
import useDocumentTitle from "lib/useDocumentTitle";
import QRCode from "qrcode";
import { useParams } from "react-router-dom";
import { useClient } from "urql";

const GenerateStaticJwtQrDocument = graphql(`
    query GenerateStaticJwtQrDocument($projectSessionId: ID!) {
        generateStaticJwtQr(subjectType: "ProjectSession", subjectId: $projectSessionId)
    }
`)

export default function AdminProjectAttendanceTakePage() {
    useDocumentTitle("Admin Project Attendance | AIAA@UCF Members")
    const { projectId, projectSessionId } = useParams()
    const client = useClient()

    const downloadStaticQr = async () => {
        const result = await client.query(GenerateStaticJwtQrDocument, { projectSessionId: projectSessionId! })
        if (result.data?.generateStaticJwtQr != null) {
            const dataUrl = await QRCode.toDataURL(`${window.location.origin}/attendance?c=${result.data.generateStaticJwtQr}`)
            const link = document.createElement("a")
            link.download = `${projectId}-staticqr.png`
            link.href = dataUrl
            link.click()
        }
    }

    return (
        <div>
            <div className="mb-6">
                <h3 className="text-xl">Admin Scanner</h3>
                <p className="mb-2">Admin Scanner is opened on a phone and is used to scan user's MyQR</p>
                <LinkButtonPrimary to={`/admin/projects/${projectId}/${projectSessionId}/attendance/adminscan`}>Launch Admin Scanner <ArrowTopRightOnSquareIcon className="h-5 w-5 inline" /></LinkButtonPrimary>
            </div>
            <div className="mb-6">
                <h3 className="text-xl">Live QR</h3>
                <p className="mb-2">Live QR is presented through the broswer. Users must scan the QR code while it is active.</p>
                <LinkButtonPrimary to={`/admin/projects/${projectId}/${projectSessionId}/attendance/liveqr`}>Launch Live QR <ArrowTopRightOnSquareIcon className="h-5 w-5 inline" /></LinkButtonPrimary>
            </div>
            <div className="mb-6">
                <h3 className="text-xl">Static QR</h3>
                <p>Static QR is downloaded and included in a slideshow. It is only valid for the current week.</p>
                <ButtonPrimary onClick={downloadStaticQr}>Download Static QR <ArrowDownOnSquareIcon className="h-5 w-5 inline" /></ButtonPrimary>
            </div>
        </div>
    )
}