import { ArrowTopRightOnSquareIcon } from "@heroicons/react/20/solid"
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/solid"
import LoggedInConditional from "./LoggedInConditional"
import AccountDropdown, { SignInLink } from "./AccountDropdown"
import { useEffect, useState } from "react"
import { Link, useLocation } from "react-router-dom"
import MobileAccountDropdown, { MobileSignInLink } from "./MobileAccountDropdown"


export default function Topbar() {
    const location = useLocation()
    const [hamburgerOpen, setHamburgerOpen] = useState(false)

    useEffect(() => setHamburgerOpen(false), [location])

    return (
        <>

            <div className="hidden w-auto h-20 bg-aiaablue md:flex items-center px-8 justify-between text-white">
                <div className="flex items-center justify-evenly">
                    <Link to="/" className="flex flex-col items-center">
                        <img src="/aiaa-logo-white.png" alt="AIAA@UCF White Logo" height={50} width={41} />
                        {/* TODO Change to White box with both logos */}
                    </Link>
                    <Link className="ml-12 mx-4 hover:underline" to="/members">
                        Search Members
                    </Link>
                    <Link className="mx-4 hover:underline" to="/projects">
                        Search Projects
                    </Link>
                    <a href="https://www.aiaaucf.com/" target="_blank" rel="noreferrer" className="flex items-center mx-4 hover:underline">
                        Main Website
                        <ArrowTopRightOnSquareIcon className="h-5 w-5" />
                    </a>
                </div>
                <div>
                    <LoggedInConditional
                        whenLoggedIn={AccountDropdown}
                        whenNotLoggedIn={SignInLink}
                    />
                </div>
            </div>



            <div className="w-auto md:hidden">
                <div className="w-full bg-aiaablue flex items-center px-8 py-3 justify-between text-white md:hidden">
                    <div className="flex items-center justify-evenly">
                        <Link to="/" className="flex flex-col items-center">
                            <img src="/aiaa-logo-white.png" alt="AIAA@UCF White Logo" height={50} width={41} />
                            {/* TODO Change to White box with both logos */}
                        </Link>
                    </div>
                    <div>
                        {!hamburgerOpen && (
                            <button onClick={() => setHamburgerOpen(true)}>
                                <Bars3Icon className="h-8 w-8" />
                            </button>
                        )}
                        {hamburgerOpen && (
                            <button onClick={() => setHamburgerOpen(false)}>
                                <XMarkIcon className="h-8 w-8" />
                            </button>
                        )}
                    </div>
                </div>
                <div className="w-full bg-black text-white flex flex-col items-stretch justify-start text-lg truncate transition-all" style={{
                    maxHeight: hamburgerOpen ? "100%" : "0px"
                }}>
                    <div className="py-6 border-b-4 border-gray-700">
                        <LoggedInConditional
                            whenLoggedIn={MobileAccountDropdown}
                            whenNotLoggedIn={MobileSignInLink}
                        />
                    </div>

                    <Link className="px-6 hover:underline py-6 border-b-2 border-gray-700" to="/members">
                        Search Members
                    </Link>
                    <Link className="px-6 hover:underline py-6 border-b-2 border-gray-700" to="/projects">
                        Search Projects
                    </Link>
                    <a href="https://www.aiaaucf.com/" target="_blank" rel="noreferrer" className="flex items-center px-6 hover:underline py-6 border-b-2 border-gray-700">
                        Main Website
                        <ArrowTopRightOnSquareIcon className="h-5 w-5" />
                    </a>
                </div>
            </div>
        </>
    )
}