import { LinkButtonPrimaryOutlined } from "components/Button"
import LinkLegacyMembership from "components/LinkLegacyMembership"
import { graphql } from "generated"
import { constructLoginRedirectPath } from "lib/provideUrql"
import useDocumentTitle from "lib/useDocumentTitle"
import { Navigate, useLocation } from "react-router-dom"
import { useQuery } from "urql"

const GetMeDocument = graphql(`
    query AccountLegacyMembershipPageBody_GetMe {
        me {
            ...LinkLegacyMembership_UserFragment
        }
    }
`)

export default function AccountLegacyMembershipPage() {
    useDocumentTitle("Add Legacy Membership | AIAA@UCF Members")
    const { pathname } = useLocation()
    const [{ data }] = useQuery({ query: GetMeDocument })

    if (data?.me == null) {
        return <Navigate to={constructLoginRedirectPath(pathname)} />
    }

    return (
        <div>
            <h2 className="mb-2 text-2xl">Link legacy membership</h2>
            <p>Purchased a membership from the aiaaucf.com store? Link your purchase to your new AIAA@UCF Account!</p>
            <div className="my-8">
                <LinkLegacyMembership user={data.me} backAction={<LinkButtonPrimaryOutlined to="/account/membership">Back</LinkButtonPrimaryOutlined>} />
            </div>
        </div>
    )
}