import { getFragmentData, graphql } from "generated"
import { ResultOf } from '@graphql-typed-document-node/core'
import { ComponentType, ReactElement } from "react"
import { useQuery } from "urql"

const GetMeDocument = graphql(`
    query LoggedInConditional_GetMe {
        me {
            ...LoggedInConditional_UserFragment
        }
    }
`)

export const LoggedInConditional_UserFragment = graphql(`
    fragment LoggedInConditional_UserFragment on User {
        id
        givenName
        fullName
        role {
            id
            scopes
        }
    }
`)

type Props = {
    whenLoggedIn?: ComponentType<{ user: ResultOf<typeof LoggedInConditional_UserFragment> }>
    whenNotLoggedIn?: ComponentType
}

export default function LoggedInConditional(props: Props) {
    const [{ data }] = useQuery({ query: GetMeDocument })
    const user = getFragmentData(LoggedInConditional_UserFragment, data?.me)

    if (user?.id != null) {
        return (props.whenLoggedIn && <props.whenLoggedIn user={user} />) as ReactElement
    } else {
        return (props.whenNotLoggedIn && <props.whenNotLoggedIn />) as ReactElement
    }
}