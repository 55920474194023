import { motion } from "framer-motion";
import EditAccountProfile from "components/EditAccountProfile";
import { toast } from "react-toastify";
import { FragmentType, getFragmentData, graphql } from "generated";

export const OnboardingProfile_UserFragment = graphql(`
    fragment OnboardingProfile_UserFragment on User {
        id
        ...EditAccountProfile_UserFragment
    }
`)

type Props = {
    user: FragmentType<typeof OnboardingProfile_UserFragment>
    onNext(): void
}

export default function OnboardingProfile(props: Props) {
    const user = getFragmentData(OnboardingProfile_UserFragment, props.user)

    return (
        <motion.div className="flex flex-col justify-center items-center px-4" initial={{ opacity: 0, translateX: 100 }} animate={{ opacity: 100, translateX: 0 }} exit={{ translateX: -100, opacity: 0 }}>
            <p className="text-xl text-center my-6 font-bold">About you</p>
            <div className="bg-white text-black p-6 rounded-lg">
                <EditAccountProfile
                    user={user}
                    onSave={props.onNext}
                    onError={(e) => toast.error(e)}
                />
            </div>
        </motion.div>
    )
}